import React, { ChangeEvent, ReactNode, useRef } from "react";
import NavBarUserMenu from "../Components/Features/NavBar/NavBarUserMenu";
import { Avatar, Badge } from "antd";
import { useSelector } from "react-redux";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { Axios } from "../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function SettingsContentLayout({ children }: { children: ReactNode }) {
  const currentPath = window.location.pathname;
  const { t } = useTranslation();

  const user = useSelector((state: any) => state.authentication.user);
  const avatar =
    (user?.avatar &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
        user?.avatar) ||
    "";
  const avatarInput = useRef<HTMLInputElement>(null);
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      let avatarFile = new FormData();
      if (e.target?.files) {
        avatarFile.append("avatar", e.target?.files?.[0]);
        console.log(avatarFile);
        const avatarRes = await Axios.post("/users/avatar", avatarFile, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success(t(avatarRes.data.avatar.message["RESPONSE_CODE"]));
      }
    } catch (e: any) {
      toast.error(
        t(e?.response.data?.message["RESPONSE_CODE"]) ||
          "SERVICE_UNAVAILABLE_MESSAGE",
      );
    }
  };

  return (
    <div className="w-full h-full flex flex-col md:flex-row">
      <input
        type="file"
        className="hidden"
        onChange={handleChange}
        ref={avatarInput}
        accept="image/*"
      />
      <div className="w-full  md:h-full   md:w-1/3 min-w-[300px]  md:border-r md:border-gray-200 box-border flex  items-center  flex-col justify-around ">
        <div className="w-full max-h-fit md:p-0 flex  items-center  flex-col justify-evenly">
          <div className="w-full my-10   md:mt-0 flex justify-center items-center">
            <Badge
              offset={[0, 130]}
              count={
                currentPath === "/settings/profile" ? (
                  <ChangeCircleIcon
                    style={{ color: "#1E232C" }}
                    fontSize="large"
                    className="cursor-pointer"
                    onClick={() => avatarInput?.current?.click()}
                  />
                ) : (
                  0
                )
              }
            >
              <Avatar
                size={150}
                alt={user?.name}
                src={avatar}
                crossOrigin={"anonymous"}
              />
            </Badge>
          </div>
          <div className="w-full px-8 hidden md:block">
            <NavBarUserMenu MenuKey="SettingsContentMenu" />
          </div>
        </div>
      </div>
      <div className="w-full md:h-full "> {children}</div>
    </div>
  );
}

export default SettingsContentLayout;
