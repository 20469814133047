import React, { useRef, useState } from "react";
import { usePalletsData } from "../../../../Hooks/usePalletsData";
import { List, Table } from "antd";
import { PalletsColumns } from "./PalletsColumns";
import { useTranslation } from "react-i18next";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from "qrcode.react";
import LogoTrcSimple from "../../../../Assets/SVGs/LogoTrcSimple";
import { ProductStock } from "../../../../Types/PrismaSwift";
import moment from "moment/moment";
import Barcode from "react-barcode";

export function PalletsPageContent() {
  const { t } = useTranslation();
  const { palletsData, isPalletsLoading } = usePalletsData();

  let columns = PalletsColumns(t);

  const PalletTicketRef = useRef(null);
  const [palletToPrint, setPalletToPrint] = useState<any>(null);

  const printPaletTicketHandler = useReactToPrint({
    content: () => PalletTicketRef.current,
  });

  columns = columns?.map((column: any) => {
    if (column.key === "actions")
      return {
        ...column,
        render: (_, record) => (
          <div className="w-full flex justify-center items-center">
            <PrinterOutlined
              className="cursor-pointer"
              onClick={() => {
                printPaletTicketHandler();
                setPalletToPrint(record);
              }}
            />
          </div>
        ),
      };
    return column;
  });

  return (
    <div className="w-full  overflow-scroll">
      <div className="hidden">
        {palletToPrint && (
          <div ref={PalletTicketRef} className="flex w-full p-5 flex-col mb-5">
            <div className="flex w-full flex-nowrap gap-5 ">
              <div className="flex  flex-col w-full   justify-center px-5">
                <LogoTrcSimple />

                <h2 className="w-full text-center text-3xl font-bold mt-3">
                  PALLET
                </h2>
              </div>

              <QRCodeSVG value={palletToPrint.palletID} height={90} />
            </div>

            {palletToPrint.Box.length ? (
              <div className="w-full flex-col ">
                <h2>Boxes in pallet</h2>
                <div className="w-full"></div>
              </div>
            ) : null}

            {}
            {palletToPrint.ProductStock ? (
              <div className="w-full flex-col flex mt-5 text-xs">
                <div className="w-full flex justify-center uppercase !text-xl font-bold my-5">
                  Products in Pallet
                </div>
                <List
                  bordered
                  size="default"
                  rootClassName="mb-4"
                  itemLayout="horizontal"
                >
                  {palletToPrint.ProductStock.map((ps: ProductStock) => {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <div className="w-full flex justify-between">
                              <span>{ps.Product.name}</span>
                              <span>{`${t("quantity")} : ${ps.quantity}`}</span>
                            </div>
                          }
                          description={
                            <div className=" w-full flex flex-col justify-center ">
                              <div className=" w-full flex justify-between ">
                                <span> {`${t("note")} : ${ps.note}`} </span>
                                <span>
                                  {`${t("expiry_date")} : ${
                                    (ps.expiryDate &&
                                      moment(ps.expiryDate).format(
                                        "MM/YYYY",
                                      )) ||
                                    "N/A"
                                  }`}
                                </span>
                              </div>{" "}
                              <div className=" w-full flex justify-center ">
                                <Barcode
                                  value={ps.Product.bareCode || ""}
                                  displayValue={true}
                                  width={1}
                                  height={20}
                                  fontSize={10}
                                  margin={10}
                                />
                              </div>
                            </div>
                          }
                        />
                      </List.Item>
                    );
                  })}
                </List>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Table
        rowKey="palletID"
        size="small"
        loading={isPalletsLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={columns}
        dataSource={palletsData}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
      />
    </div>
  );
}
