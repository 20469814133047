import React from "react";
import { ColumnGroupType } from "antd/es/table";
import { User } from "../../../../Types/PrismaSwift";
import { ColumnType } from "antd/lib/table";
import { Avatar } from "antd";
import moment from "moment";

const avatar = (fileLink: string | null): string =>
  (fileLink &&
    (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
      (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
      fileLink) ||
  "";

export const SwiftUsersColumns: (
  t: any,
  moment: any,
  openModal?: any,
) => (ColumnGroupType<User> | ColumnType<User>)[] = (t) => [
  {
    dataIndex: "avatar",
    key: "avatar",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <Avatar src={avatar(value)} />
      </div>
    ),
  },
  {
    title: t("name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value.replaceAll("_", " "),
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("lastName"),
    dataIndex: "lastName",
    key: "lastName",
    ellipsis: true,

    sorter: (a, b) => {
      return a.lastName.localeCompare(b.lastName);
    },
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.lastName.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("email"),
    dataIndex: "email",
    key: "email",
    ellipsis: true,

    sorter: (a, b) => {
      return a.email.localeCompare(b.email);
    },
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.email.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("isActive"),
    dataIndex: "isActive",
    key: "isActive",
    render: (value) => value,
    onFilter: (value: any, record) => record.lastName.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("isVerified"),
    dataIndex: "isVerified",
    key: "isVerified",
    render: (value) => value,
    onFilter: (value: any, record) => record.lastName.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("profile"),
    dataIndex: "profile",
    key: "profile",
    render: (value) => t(value),
    onFilter: (value: any, record) => record.lastName.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("role"),
    dataIndex: "role",
    key: "role",
    render: (value) => t(value),
    onFilter: (value: any, record) => record.lastName.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("lastActive"),
    dataIndex: "lastActive",
    key: "lastActive",
    onFilter: (value: any, record) =>
      record.lastActive?.startsWith(value) || false,
    filterSearch: true,
    render: (value) =>
      (value &&
        moment(new Date(value), "YYYYMMDD").startOf("hour").fromNow()) ||
      t("never_active"),
  },
];

/*{
  title: t("actions"),
      dataIndex: "warehouseID",
    key: "warehouseID",
    render: () => (
    <div className="w-full flex justify-center items-center">
      <DeleteForeverIcon
          fontSize="small"
          color="error"
          className="cursor-pointer"
      />
      <LocalPrintshopIcon
          fontSize="small"
          color="action"
          className="cursor-pointer"
          onClick={() => openModal(true)}
      />
    </div>
),
},*/
