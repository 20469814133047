import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { Product } from "../Types/PrismaSwift";

export const usePalletsData = (refreshInterval: number | undefined = 10000) => {
  const { t } = useTranslation();

  const [pallets, setPallets] = useState<Product[] | []>([]);

  const {
    data: palletsData,
    isLoading: isPalletsLoading,
    error: productError,
  } = useSwiftSWR("/inventory/get-pallets", {
    refreshInterval,
  });

  useEffect(() => {
    const handlePalletsData = () => {
      if (!isPalletsLoading && palletsData) {
        const dataToShow = palletsData?.pallets;
        setPallets(dataToShow);
      }
    };

    const handlePalletsError = () => {
      if (productError) {
        toast.error(
          t(
            productError?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handlePalletsData();
    handlePalletsError();

    // eslint-disable-next-line
  }, [palletsData, isPalletsLoading, productError]);

  return { palletsData: pallets, isPalletsLoading, productError };
};
