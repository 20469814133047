import { Product } from "../../../../Types/PrismaSwift";
import { QRCodeSVG } from "qrcode.react";
import React, { useRef } from "react";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "antd";

export function ProductDetails({ product }: { product: Product }) {
  const productDetailsRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => productDetailsRef.current,
  });

  const { t } = useTranslation();

  return (
    <>
      <div className="w-full flex p-4 mb-4">
        <Button
          type="primary"
          className="!h-10 !bg-[#D63031] !hover:bg-[#E44C4D] !text-white"
          onClick={handlePrint}
        >
          {t("print_product_infos")}
        </Button>
      </div>

      <div ref={productDetailsRef} className="p-5">
        <div className="px-4 sm:px-0 flex ">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {t("product_information")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ml-auto">
            <QRCodeSVG value={product.productID} size={90} />
          </p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Product name
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {product.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {t("product_description")}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {product.description}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {t("product_bareCode")}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <Barcode
                  value={product?.bareCode || ""}
                  displayValue={true}
                  height={40}
                  fontSize={14}
                  margin={0}
                  background="none"
                />
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {t("product_category")}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <QRCodeSVG
                  value={product?.ProductCategory?.productCategoryID}
                  size={40}
                />
                <span className="text-sm leading-6 text-gray-700 ml-4">
                  {product?.ProductCategory?.name}
                </span>
              </dd>
            </div>
            <div className=" hidden px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {t("products_stock")}
              </dt>
              <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {product.ProductStock && (
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {product.ProductStock.map((ps) => {
                      return (
                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">
                                {ps.Warehouse?.name.replaceAll("_", " ")}
                              </span>
                              <span className="flex-shrink-0 text-gray-400">
                                EXP:{" "}
                                {ps.expiryDate
                                  ? moment(ps.expiryDate).format("YYYY-MM-DD")
                                  : null}
                              </span>
                            </div>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <h3 className="font-medium text-indigo-600 hover:text-indigo-500">
                              {ps.quantity}
                            </h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
