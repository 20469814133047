import React, { ReactNode } from "react";
import { Table } from "antd";
import { StorageZonesColumns } from "./StorageZonesColumns";
import { useStorageZonesData } from "../../../../Hooks";

export function StorageZonesContent() {
  const { storageZones, isStorageZonesLoading: isLoading } =
    useStorageZonesData();

  /*const renderExpendable = (data: any): ReactNode => {
          return (
            <Table
              key={data.warehouseID}
              size="middle"
              loading={isLoading}
              columns={WarehousesColumns}
              dataSource={[]}
            />
          );
        };*/
  const renderDisExpendable = (data: any): ReactNode => {
    return <p className={"p-8 w-full"}>{data.description}</p>;
  };
  return (
    <div className="w-full  overflow-scroll">
      <Table
        rowKey="productCategoryID"
        size="small"
        loading={isLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={StorageZonesColumns}
        dataSource={storageZones}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: renderDisExpendable,
        }}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
      />
    </div>
  );
}
