import React, { ReactNode } from "react";
import { Button, Table } from "antd";
import { ProductsStockColumns } from "./ProductsStockColumns";
import { useTranslation } from "react-i18next";
import { ProductsStockSubColumns } from "./ProductsStocksubColumns";
import { useProductStockData } from "../../../../Hooks";
import { ProductStock } from "../../../../Types/PrismaSwift";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { useNavigate } from "react-router";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";

export function ProductsStockContent() {
  const { t } = useTranslation();

  const { productsStock, isProductsStockLoading } = useProductStockData();
  const navigate = useNavigate();

  let tableColumns = ProductsStockColumns(t) as unknown as (
    | ColumnGroupType<ProductStock>
    | ColumnType<ProductStock>
  )[];
  const tableSubColumns = ProductsStockSubColumns(t);

  tableColumns = tableColumns.map((column: any) => {
    if (
      column.key === "name" ||
      column.key === "bareCode" ||
      column.key === "ProductCategory"
    ) {
      const key = column.key;
      const filters = productsStock?.map((row: any) => {
        return column.key === "ProductCategory"
          ? {
              text: row[key].name.replaceAll("_", " "),
              value: row[key].name,
            }
          : {
              text: row[key].replaceAll("_", " "),
              value: row[key],
            };
      });

      const uniqueFilter = filters.reduce((acc: any[], value: any) => {
        if (!acc.some((accVal: any) => accVal.value === value.value)) {
          acc.push(value);
        }
        return acc;
      }, []);

      return { ...column, filters: uniqueFilter };
    } else {
      return column;
    }
  });

  const productStockExpendable = (data: any): ReactNode => {
    return (
      <Table
        key={data?.productID}
        size="small"
        loading={isProductsStockLoading}
        columns={tableSubColumns}
        dataSource={data.ProductStock}
        rootClassName="max-w-full"
        tableLayout={"auto"}
        bordered={true}
      />
    );
  };

  return (
    <div className="w-full  overflow-scroll">
      <div className="w-full flex p-4 mb-4 justify-between">
        <Button
          type="primary"
          className="!h-10 !bg-[#D63031] !hover:bg-[#E44C4D] !text-white"
          onClick={() => navigate("/inventory/add_product-stock")}
        >
          {t("add_product_stock")}
        </Button>
        {productsStock.length ? (
          <Button
            type="primary"
            className="!h-10 !bg-[#D63031] !hover:bg-[#E44C4D] !text-white"
          >
            <CsvDownloadButton
              data={productsStock}
              filename={`Export Produits en stock ${moment().format(
                "DD/MM/yyyy",
              )}`}
            />
          </Button>
        ) : null}
      </div>
      <Table
        rowKey="productID"
        size="small"
        loading={isProductsStockLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={tableColumns}
        dataSource={productsStock}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: productStockExpendable,
        }}
        tableLayout={"auto"}
        bordered={true}
      />
    </div>
  );
}
