import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import React from "react";

export function AddPalletPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_pallet")}>
        <div className="h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4">
          add_pallet
        </div>
      </PageLayout>
    </AppLayout>
  );
}
