import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { ProductCategory } from "../Types/PrismaSwift";

export const useProductCategories = () => {
  const { t } = useTranslation();

  const [productsCategories, setProductsCategories] = useState<
    ProductCategory[] | []
  >([]);

  const {
    data,
    isLoading: isProductsCategoriesLoading,
    error: productsCategoriesError,
  } = useSwiftSWR("/inventory/get-product-categories", {
    refreshInterval: 10000,
  });

  useEffect(() => {
    const handleProductCategories = () => {
      if (!isProductsCategoriesLoading && data) {
        const dataToShow = data["product-categories"];
        setProductsCategories(dataToShow);
      }
    };

    const handleProductCategoriesError = () => {
      if (productsCategoriesError) {
        toast.error(
          t(
            productsCategoriesError?.response.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleProductCategories();
    handleProductCategoriesError();

    // eslint-disable-next-line
  }, [data, isProductsCategoriesLoading, productsCategoriesError, t]);

  return {
    productsCategories,
    isProductsCategoriesLoading,
    productsCategoriesError,
  };
};
