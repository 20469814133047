import DashboardSquareCard from "../../UI/DashboardSquareCard";
import {
  AuditOutlined,
  BarsOutlined,
  ReadOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const DashboardInfoCards = () => {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col md:flex-row w-full mt-7 md:mt-14 justify-evenly  ">
      <DashboardSquareCard
        Icon={TeamOutlined}
        title={t("my_teams")}
        count={6}
        cb={() => {}}
      />
      <DashboardSquareCard
        Icon={AuditOutlined}
        title={t("my-contribution")}
        count={0}
      />
      <DashboardSquareCard
        Icon={BarsOutlined}
        title={t("my_missions")}
        count={7}
      />
      <DashboardSquareCard
        Icon={ReadOutlined}
        title={t("my_learning")}
        count={1}
      />
    </div>
  );
};
