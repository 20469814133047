import { FC, PropsWithChildren } from "react";
import authWallpaper from "../Assets/Images/authWallpaper.jpg";
import { useTranslation } from "react-i18next";
import { LanguageToggle, LinksFooter } from "../Components";
import TrcSwiftLogo from "../Assets/SVGs/TrcSwiftLogo";

export const AuthLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <main
      className={`w-screen h-screen max-h-screen flex flex-nowrap ${
        language === "ar" ? "right-to-left" : ""
      }`}
    >
      <div className="sm:min-w-4/6 sm:w-4/6 hidden sm:flex h-full relative">
        <img
          src={authWallpaper}
          alt="authWallpaper"
          className="w-full h-full object-left object-cover"
        />
        <span className="absolute bottom-8 w-full text-center text-white text-xs">
          {`© SWIFT ${new Date().getFullYear()} - ${t(
            "auth_all_rights_reserved",
          ).toUpperCase()}`}
        </span>
      </div>

      <div className="w-full sm:w-2/6 sm:min-w-[550px] h-screen min-h-screen max-h-screen relative">
        <div className="w-full h-full  flex flex-col p-5 sm:p-10 px-10 sm:px-16 justify-between relative">
          <LanguageToggle className="self-end mb-20 sm:mb-10 " />
          <TrcSwiftLogo className="w-full flex justify-center mb-3 min-w-full" />
          <div className="w-full max-h-full h-full flex flex-col justify-center relative ">
            {children}
          </div>
          <LinksFooter />
        </div>
      </div>
    </main>
  );
};
