import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSwiftSWR } from "../Config";
import { setProductsDataAction } from "../Store/actions";
import { toast } from "react-toastify";
import { Product } from "../Types/PrismaSwift";

export const useProductData = (refreshInterval: number | undefined = 10000) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [products, setProducts] = useState<Product[] | []>([]);

  const {
    data: productData,
    isLoading: isProductLoading,
    error: productError,
  } = useSwiftSWR("/inventory/get-products", {
    refreshInterval,
  });

  useEffect(() => {
    const handleProductData = () => {
      if (!isProductLoading && productData) {
        const dataToShow = productData.products;
        setProducts(dataToShow);
        dispatch(setProductsDataAction(dataToShow));
      }
    };

    const handleProductError = () => {
      if (productError) {
        toast.error(
          t(
            productError?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleProductData();
    handleProductError();

    // eslint-disable-next-line
  }, [productData, isProductLoading, productError]);

  return { products, isProductLoading, productError };
};
