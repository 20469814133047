import { object, ref, string } from "yup";

export const changePasswordValidation = object({
  oldPassword: string().required("field_should_have_value"),
  newPassword: string()
    .min(8, "password_length_validation")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "password_strong_validation",
    )
    .required("field_should_have_value"),
  confirmPassword: string()
    .oneOf([ref("newPassword")], "passwords_must_match")
    .required("field_should_have_value"),
});
