import { AuthLayout } from "../../Layouts";
import { useTranslation } from "react-i18next";
import { OtpVerificationForm } from "../../Components";

export const OtpVerificationPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className="w-full h-full  flex flex-col my-auto mt-14">
        <h3 className="text-[#1E232C] text-4xl font-semibold mb-10">
          {t("auth_forgot_password")}
        </h3>
        <span className="text-[#8391A1] text-sm font-light mb-10">
          {t("auth_forgot_password_subtitle")}
        </span>
        <OtpVerificationForm
          onSubmit={() => {
            console.log("g");
          }}
        />
      </div>
    </AuthLayout>
  );
};
