import React from "react";
import DisabledInput from "../../UI/DisabledInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ProfilePageContent() {
  const user = useSelector((state: any) => state.authentication.user);
  const { t } = useTranslation();
  return (
    <div className="h-fit md:h-full flex flex-col justify-center items-center p-8">
      <div className="w-full max-h-fit flex flex-col md:flex-row md:flex-wrap justify-evenly">
        <DisabledInput
          title={t("role")}
          content="ADMIN"
          containerProps={{
            className: "w-full md:w-2/5 mb-5",
            style: { color: "#DC0D16" },
          }}
        />
        <DisabledInput
          title={t("unique_id")}
          content={user.userID}
          containerProps={{
            className: "w-full md:w-2/5 mb-5",
            style: { color: "#DC0D16" },
          }}
        />
        <DisabledInput
          title={t("name")}
          content={user.name}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
        <DisabledInput
          title={t("lastName")}
          content={user.lastName}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
        <DisabledInput
          title={t("auth_email")}
          content={user.email}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
        <DisabledInput
          title={t("phone")}
          content={user.phone}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
        <DisabledInput
          title={t("committee")}
          content={user.Committee.nameLatin}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
        <DisabledInput
          title={t("date_of_creations")}
          content={user.DoC.substring(0, 10)}
          containerProps={{ className: "w-full md:w-2/5 mb-5" }}
        />
      </div>
    </div>
  );
}
