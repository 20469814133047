import { Axios } from "./axios";
import useSWR from "swr";
import { store } from "../Store/store";

export const swrConfig = {
  fetcher: (url: string) =>
    Axios.get(url, {
      headers: {
        Authorization:
          window.localStorage.getItem("token") ||
          store.getState().authentication.token ||
          "",
      },
    }).then((res) => res.data),
};

export const useSwiftSWR = (key: string, options?: any) => {
  return useSWR(key, { ...options, ...swrConfig });
};
