import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const LinksFooter = () => {
  const { t } = useTranslation();
  return (
    <div className=" w-full flex justify-between items-center font-light text-xs text-[#141414]">
      <Link to="/about_us" className="mx-1  text-center">
        {t("about_us")}
      </Link>
      <Link to="/privacy_policy" className="mx-1 text-center">
        {t("privacy_policy")}
      </Link>
      <Link to="/terms_of_service" className="mx-1 text-center">
        {t("terms_of_service")}
      </Link>
      <Link to="/contact_us" className="mx-1 text-center">
        {t("contact_us")}{" "}
      </Link>
    </div>
  );
};
