import Barcode from "react-barcode";
import React from "react";

function ProductSmallTicketPrintable({ productToPrint }: any) {
  return (
    <div className="    min-h-full flex flex-col  p-4  box-border ">
      <div className=" flex flex-nowrap  items-center    font-bold max-w-full justify-center ">
        {productToPrint.name}
      </div>
      <div className=" flex flex-nowrap justify-between py-2 items-center relative">
        <Barcode
          value={productToPrint?.bareCode || ""}
          displayValue={true}
          height={40}
          fontSize={10}
          margin={0}
          background="none"
        />
      </div>
    </div>
  );
}

export default ProductSmallTicketPrintable;
