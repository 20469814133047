import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Button, Dropdown, Input, Space } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { ForgotPassHandlerPropsT } from "../../../Types";
import { ForgotPassHandler } from "../../../Services";
import { toast } from "react-toastify";
import { ForgetPasswordValidation } from "../../../Validation";

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const initialFormState = { email: "", sendMethode: "EMAIL" };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitHandler = async (values: ForgotPassHandlerPropsT) => {
    try {
      setIsLoading(true);
      console.log("d");
      const userResponse = await ForgotPassHandler(values);
      toast.success(t(userResponse.data?.message["RESPONSE_CODE"]));
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);

      toast.error(
        t(e?.response?.data?.message["RESPONSE_CODE"]) ||
          "SERVICE_UNAVAILABLE_MESSAGE",
      );
    }
  };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={ForgetPasswordValidation}
      onSubmit={onSubmitHandler}
      isInitialValid={false}
    >
      {({
        errors,
        setFieldValue,
        isValid,
        submitForm,
        handleChange,
        handleBlur,
        values,
      }) => {
        return (
          <>
            <Input
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t("auth_email")}
              size="large"
              status={errors.email ? "error" : ""}
            />

            <h3 className="mt-2 mb-5 mx-2 w-full text-sm  text-[#ff4d4f]">
              {errors.email && t(errors.email)}
            </h3>

            <Dropdown
              menu={{
                items: [
                  { key: "SMS", label: "SMS" },
                  { key: "EMAIL", label: "EMAIL" },
                ],
                onClick: (e) => setFieldValue("sendMethode", e.key),
              }}
            >
              <Button size="large">
                <Space className="w-full flex justify-between items-center">
                  {values.sendMethode}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <div className="w-full flex justify-between border-t border-t-[#E5E5E5] flex-col  mb-auto">
              <h3 className=" text-xs  ml-3 cursor-pointer mb-8 mt-4 w-full text-center ">
                {t("auth_remember_your_password")}{" "}
                <Link to="/login" className="text-[#D63031] font-medium">
                  {t("login_title")}
                </Link>
              </h3>
              <Button
                type="primary"
                className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                onClick={submitForm}
                disabled={!isValid || isLoading}
                loading={isLoading}
              >
                {t("send")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
