import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { Col, Row, Statistic } from "antd";

export function InventoryPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("inventory_analysis")}>
        <div className="h-fit md:h-screen w-full bg-white  rounded-xl shadow-accent-foreground shadow-2xl ">
          <div className=" p-8 w-full">
            <Row gutter={16}>
              <Col span={4}>
                <Statistic title="Active Users" value={112893} />
              </Col>
              <Col span={4}>
                <Statistic title="Active Users" value={112893} />
              </Col>
              <Col span={4}>
                <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
                />
              </Col>
              <Col span={4}>
                <Statistic title="Active Users" value={112893} />
              </Col>
              <Col span={4}>
                <Statistic title="Active Users" value={112893} />
              </Col>
              <Col span={4}>
                <Statistic title="Active Users" value={112893} />
              </Col>
            </Row>
          </div>
        </div>
      </PageLayout>
    </AppLayout>
  );
}
