import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import WtcListingPage from "../Pages/WtcPages/WtcListingPage";
import AddWtcPage from "../Pages/WtcPages/AddWtcPage";

export function WtcRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/wtc/wtc_listing"
        element={
          <PrivateRoute>
            <WtcListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/wtc/add_wtc"
        element={
          <PrivateRoute>
            <AddWtcPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
