import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { StorageZone } from "../Types/PrismaSwift";

export const useStorageZonesData = () => {
  const { t } = useTranslation();

  const [storageZones, setStorageZones] = useState<StorageZone[] | []>([]);

  const { data, isLoading, error } = useSwiftSWR(
    "/inventory/get-storage-zones",
    {
      refreshInterval: 10000,
    },
  );

  useEffect(() => {
    const handleStorageZones = () => {
      if (!isLoading && data) {
        const dataToShow = data["storage-zones"];
        setStorageZones(dataToShow);
      }
    };

    const handleStorageZonesError = () => {
      if (error) {
        toast.error(
          t(
            error?.response.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleStorageZones();
    handleStorageZonesError();

    // eslint-disable-next-line
  }, [data, isLoading, error, t]);

  return {
    storageZones,
    isStorageZonesLoading: isLoading,
    storageZonesError: error,
  };
};
