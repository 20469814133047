import React from "react";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import SettingsContentLayout from "../../Layouts/SettingsContentLayout";
import Settings2FaContant from "../../Components/Features/Settings/Settings2FAContant";
import { useTranslation } from "react-i18next";

function Settings2FaPage() {
  const { t } = useTranslation();
  return (
    <AppLayout>
      <PageLayout title={t("two_factors_authentification")}>
        <div className="h-fit md:h-screen w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl ">
          <SettingsContentLayout>
            <Settings2FaContant />
          </SettingsContentLayout>
        </div>
      </PageLayout>
    </AppLayout>
  );
}

export default Settings2FaPage;
