import { ProductCategory } from "../../Types/PrismaSwift";
import { SET_PRODUCTS_CATEGORY_DATA, USER_LOGOUT } from "../actionTypes";

export type ProductsCategoriesState = ProductCategory[] | [];

const initialState: ProductCategory[] | [] = [];
const productsCategoriesReducer = (
  state: ProductsCategoriesState = initialState,
  { type, payload }: { type: string; payload: ProductCategory[] | [] },
): ProductsCategoriesState => {
  switch (type) {
    case USER_LOGOUT:
      return initialState;
    case SET_PRODUCTS_CATEGORY_DATA:
      return payload;

    default:
      return state;
  }
};
export default productsCategoriesReducer;
