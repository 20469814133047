import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import {
  AddBoxPage,
  AddPalletPage,
  AddProductStockPage,
  BoxesPage,
  InventoryPage,
  MoveProductStockPage,
  PalletsPage,
  ProductsCategories,
  ProductsPage,
  ProductsStock,
  StorageZones,
  WarehousesPage,
} from "../Pages";

export function InventoryRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/inventory"
        element={
          <PrivateRoute>
            <InventoryPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/warehouses"
        element={
          <PrivateRoute>
            <WarehousesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/storage-zones"
        element={
          <PrivateRoute>
            <StorageZones />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/products"
        element={
          <PrivateRoute>
            <ProductsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/add_product-stock"
        element={
          <PrivateRoute>
            <AddProductStockPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/products-stock"
        element={
          <PrivateRoute>
            <ProductsStock />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/products-categories"
        element={
          <PrivateRoute>
            <ProductsCategories />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/boxes"
        element={
          <PrivateRoute>
            <BoxesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/pallets"
        element={
          <PrivateRoute>
            <PalletsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/add_box"
        element={
          <PrivateRoute>
            <AddBoxPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/add_pallet"
        element={
          <PrivateRoute>
            <AddPalletPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/move_product_stock"
        element={
          <PrivateRoute>
            <MoveProductStockPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
