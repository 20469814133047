import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import React from "react";
import { BoxesPageContent } from "../../Components/Features/Inventory/Boxes/BoxesPageContent";

export function BoxesPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("boxes")}>
        <div className="h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4">
          <BoxesPageContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
