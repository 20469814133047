import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { useSwiftSWR } from "../../Config";
import { LoadingPage } from "../../Pages/GeneralPages/LoadingPage";
import { userLoginAction, userLogoutAction } from "../../Store/actions";
import { useSWRConfig } from "swr";

export const PrivateRoute = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const { mutate } = useSWRConfig();
  const isAuthenticated = useSelector(
    (state: any) => state.authentication.isAuthenticated,
  );

  const { data, isLoading, error } = useSwiftSWR("/auth/check", {
    refreshInterval: 1000 * 60 * 10,
  });

  useEffect(() => {
    if (!isLoading && token && error) {
      navigate("/login");
      mutate(() => true, undefined, false).then((_) => {
        dispatch(userLogoutAction());
      });
    } else if (token && data) {
      dispatch(userLoginAction(data.user));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!token || (token && !isAuthenticated && !data)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
