import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";
import { User } from "../Types/PrismaSwift";
import { useDispatch } from "react-redux";
import { setSwiftUsersDataAction } from "../Store/actions/SwiftUsersActions";

export const useSwiftUsersData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [swiftUsers, setSwiftUsers] = useState<User[] | []>([]);

  const {
    data,
    isLoading: isSwiftUsersLoading,
    error: swiftUsersError,
  } = useSwiftSWR("/users/my-committee-users", {
    refreshInterval: 10000,
  });

  useEffect(() => {
    const handleProductCategories = () => {
      if (!isSwiftUsersLoading && data) {
        const dataToShow = data["users"];
        setSwiftUsers(dataToShow);
        dispatch(setSwiftUsersDataAction(dataToShow));
      }
    };

    const handleProductCategoriesError = () => {
      if (swiftUsersError) {
        toast.error(
          t(
            swiftUsersError?.response.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleProductCategories();
    handleProductCategoriesError();

    // eslint-disable-next-line
  }, [data, isSwiftUsersLoading, swiftUsersError, t]);

  return {
    swiftUsers,
    isSwiftUsersLoading,
    swiftUsersError,
  };
};
