import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { AddNewWtcContent } from "../../Components/Features/WtcManagement/AddWtc";

const AddWtcPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_wtc")}>
        <div className="h-fit p-8  w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <AddNewWtcContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default AddWtcPage;
