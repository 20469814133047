import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import logo from "../../Assets/Images/logo.png"
import facebook from "../../Assets/Images/Facebook.png"
import Twitter from "../../Assets/Images/Twitter.png"
import LinkedIn from "../../Assets/Images/LinkedIn.png"
import Youtube from "../../Assets/Images/YouTube.png"
import Instagram from "../../Assets/Images/Instagram.png"
import { Button, } from "antd";
import moment from "moment";
import { LanguageToggle } from "../../Components";


const Page: React.FC = () => {
  const { t,   i18n: { language }, } = useTranslation();
  const navigate = useNavigate();
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
    const openLink = (url:any) => {
    window.open(url, '_blank');
  };
  moment.locale(language);
  return (
    <>
    <div className="bg-white-A700 flex flex-col items-center justify-end mx-auto p-8 sm:px-5 w-full">
      <div className="flex flex-col items-center justify-start max-w-[1612px] mt-[9px] mx-auto md:px-5 w-full">
        <div className="flex flex-row font-roboto md:gap-10 items-start justify-between w-full">
          <img
            className="h-[55px]"
            src={logo}
            alt="logocolored"
            onClick={()=>{navigate("/")}}
          />
          <div className="flex flex-col items-center justify-start mt-3">
            <div className="flex flex-row gap-2 items-start justify-between w-full">
              <div
                className="text-[17px] text-black-900 tracking-[1.02px]"
              >
               <LanguageToggle/>
              </div>
            </div>
          </div>
        </div>
        <div
className="mt-[234px] rotate-[1deg] sm:text-[40px] md:text-[46px] text-[50px] text-gray-900_01 tracking-[-0.50px]"
style={{ fontFamily: 'Urbanist', fontWeight: 'bold' }}
>
{t("page_not_found")}
</div>
        <div
          className="mt-[47px] rotate-[1deg] md:text-12xl text-9xl text-red-500 tracking-[-1.28px]"
        >
          404
        </div>
        <div
          className="leading-[50.00px] mt-[41px] sm:text-[31px] md:text-[33px] text-[35px] text-black-900_72 text-center"
        >
         {t("PAGE_NOT_FOUND_MESSAGE")}
        </div>
        <Button
className="cursor-pointer font-bold font-roboto h-10 mt-[86px] text-[15px] text-center tracking-[0.30px] w-[466px]"
style={{ backgroundColor: '#1D222C', color: 'white' }}
>
          Retour
        </Button>
        <div className="flex md:flex-row flex-row font-inter md:gap-5 items-start justify-start mt-[257px] w-full">
          <div className="flex flex-col items-center justify-start">
            <div
              className="mb-[3px] mt-1.5 text-base text-center text-gray-600"              >
              © {currentYear} {t("auth_all_rights_reserved")}
            </div>
          </div>
          <div className="flex flex-row gap-6 items-start justify-start md:ml-[0] ml-[252px] md:mt-0 mt-1.5 w-auto" style={{ marginLeft: '190px' }}>
            <div className="flex flex-col h-4 items-center justify-start px-1 w-4">
              <img
                className="h-4"
                src={facebook}
                alt="facebook"
                style={{ cursor: 'pointer' }}
                onClick={() => openLink('https://www.facebook.com/CRTunisien')}
              />
            </div>
            <img
              className="h-4 w-4"
              src={Instagram}
              alt="Instagram"
              style={{ cursor: 'pointer' }}
              onClick={() => openLink('https://www.instagram.com/crt.megrine')}
            />
            <div className="flex flex-col h-4 items-center justify-start w-4">
              <img
                className="h-4 w-4"
                src={LinkedIn}
        alt="linkedin"
        style={{ cursor: 'pointer' }}
        onClick={() => openLink('https://www.linkedin.com/company/croissant-rouge-tunisien/mycompany/verification/')}
              />
            </div>
            <div className="flex flex-col h-4 items-center justify-start w-4">
              <img
                className="h-[13px]"
                src={Twitter}
        alt="twitter"
        style={{ cursor: 'pointer' }}
        onClick={() => openLink('https://www.facebook.com/CRTunisien')}
              />
            </div>
            <div className="flex flex-col h-4 items-center justify-end py-0.5 w-4">
              <img
                className="h-[11px]"
                src={Youtube}
                alt="youtube"
                style={{ cursor: 'pointer' }}
                onClick={() => openLink('https://www.linkedin.com/company/crt')}
              />
            </div>
          </div>
          <div
          style={{ cursor: 'pointer', marginLeft: '120px' }}
          onClick={() => navigate("/about_us")}
          >
           {t("about_us")}
          </div>
       <div style={{ cursor: 'pointer' }}
                onClick={() => navigate("/privacy_policy")}>{t("privacy_policy")}</div>

            <div style={{ cursor: 'pointer' }}
                onClick={() => navigate("/terms_of_service")}>{t("terms_of_service")}</div>
       
            <div  style={{ cursor: 'pointer' }}
                onClick={() => navigate("/contact_us")}>{t("contact_us")}</div>
          <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate("/contact_us")}
          >
            {t("help_center")}
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Page;
