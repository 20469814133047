import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { t } from "i18next";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uniqueObjectsByProperty<T>(arr: T[], property: keyof T): T[] {
  const uniqueValues: T[] = [];
  const seenValues: Set<any> = new Set();

  for (const item of arr) {
    const value = item[property];
    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueValues.push(item);
    }
  }

  return uniqueValues;
}

export const enumsToSelectOptions = (enums: any) => {
  return (
    (enums &&
      enums.map((enu: string[]) => {
        return { value: enu, label: t(enu) };
      })) ||
    []
  );
};

export const fileUrlHandler = (target: string | null) => {
  let ENDPOINT_PREFIX = process.env.REACT_APP_API_ENDPOINT_PREFIX;

  setInterval(() => {
    ENDPOINT_PREFIX = process.env.REACT_APP_API_ENDPOINT_PREFIX;
  }, 1000);

  return (
    (target &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (ENDPOINT_PREFIX as string) +
        "/media/" +
        target) ||
    ""
  );
};

export const enums = {
  billStatus: ["PAYED", "PARTIALLY_PAYED", "WAITING_PAYMENT", "CANCELED"],
  AccountTypes: ["PLATFORM", "ENTREPRISE", "CONSULTANT", "EXTERNAL"],
  CompanyTypes: ["PP", "PM", "SA"],
  DriverTypes: ["FIRST", "SECOND"],
  ClientTypes: ["PP", "PM", "SA"],
  IDTypes: ["CIN", "PASSPORT", "RESIDENT_PERMIT", "RNE"],

  TaxTypes: ["PERCENTAGE", "FIXED"],
  HttpMethods: ["GET", "POST", "PUT", "DELETE", "PATCH"],
  IvoicesBillsStatus: ["PENDING", "VALIDATED", "REFUSED", "ON_HOLD"],
  paymentPasserelle: ["BANK_TRANSFER", "CHEQUE", "ESPECE", "BANK_CARD"],
  WithholdingType: ["INVOICE", "SALARY", "CONTRACT"],
  WithholdingTaxPeriodType: ["DATE", "MONTH", "QUARTER", "YEAR"],
  ReservationStatus: ["PENDING", "CONFIRMED", "CANCELLED", "COMPLETED"],
};
