import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import UsersManagementPage from "../Pages/SwiftManagementPages/UsersManagementPage";

export function SwiftManagementRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/swift_management/users_management"
        element={
          <PrivateRoute>
            <UsersManagementPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
