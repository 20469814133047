import React, { ReactNode } from "react";
import { Table } from "antd";
import { BoxesColumns } from "./BoxesColumns";
import { useTranslation } from "react-i18next";
import { PrinterOutlined } from "@ant-design/icons";
import { useBoxesData } from "../../../../Hooks/useBoxesData";
import { BoxesSubColumns } from "./BoxesSubColumns";

export function BoxesPageContent() {
  const { t } = useTranslation();
  const { boxesData, isBoxesLoading } = useBoxesData();

  let columns = BoxesColumns(t);

  columns = columns?.map((column: any) => {
    if (column.key === "actions")
      return {
        ...column,
        render: (_) => (
          <div className="w-full flex justify-center items-center">
            <PrinterOutlined className="cursor-pointer" onClick={() => {}} />
          </div>
        ),
      };
    return column;
  });

  const tableSubColumns = BoxesSubColumns(t);

  const productStockExpendable = (data: any): ReactNode => {
    return (
      <Table
        key={data?.boxID}
        size="small"
        loading={isBoxesLoading}
        columns={tableSubColumns}
        dataSource={data.ProductStock}
        rootClassName="max-w-full"
        tableLayout={"auto"}
        bordered={true}
      />
    );
  };

  return (
    <div className="w-full  overflow-scroll">
      <Table
        rowKey="boxID"
        size="small"
        loading={isBoxesLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={columns}
        dataSource={boxesData}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: productStockExpendable,
        }}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
      />
    </div>
  );
}
