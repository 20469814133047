import Barcode from "react-barcode";
import React from "react";
import LogoTrcSimple from "../../Assets/SVGs/LogoTrcSimple";
import { QRCodeSVG } from "qrcode.react";

function ProductLargeTicketPrintable({ productToPrint }: any) {
  return (
    <div className="    min-h-full flex flex-col  w-[567px]    p-4  box-border ">
      <div className=" flex flex-nowrap justify-between py-2 items-center relative gap-3">
        <div className="w-2/6">
          <LogoTrcSimple />
        </div>

        <Barcode
          value={productToPrint?.bareCode || ""}
          displayValue={true}
          height={30}
          fontSize={10}
          margin={0}
          background="none"
        />

        <QRCodeSVG value={productToPrint?.productID} size={70} />
      </div>
      <div className=" flex flex-nowrap  items-center py-2  mt-5 font-bold max-w-full">
        {"Product Name".toUpperCase()} : {productToPrint.name}
      </div>
      <div className=" flex flex-nowrap  items-center  py-2    font-bold  max-w-full">
        {"Product Description".toUpperCase()} : {productToPrint.description}
      </div>
      <div className=" flex flex-nowrap  items-center  py-2    font-bold  max-w-full">
        {"Product Category".toUpperCase()} :
        <QRCodeSVG
          value={productToPrint?.ProductCategory?.productCategoryID}
          size={50}
          className="mx-5"
        />
        {productToPrint.ProductCategory.name}
      </div>
      <div className=" flex flex-nowrap  items-center  py-2    font-bold  max-w-full">
        <div className="w-1/2">
          {"Unit".toUpperCase()} : {productToPrint.unit}
        </div>
        <div className="w-1/2">
          {"EXP DATE".toUpperCase()} : {productToPrint.expiryDate}
        </div>
      </div>
    </div>
  );
}

export default ProductLargeTicketPrintable;
