export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_GET_ACTIVITY_HISTORY = "USER_GET_ACTIVITY_HISTORY";
export const CHANGE_OTP_STATUS = "CHANGE_OTP_STATUS";
export const GENERATE_OTP_CODE = "GENERATE_OTP_CODE";
export const VERIFY_OTP_CODE = "VERIFY_OTP_CODE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SET_WAREHOUSES_DATA = "SET_WAREHOUSES_DATA";
export const SET_PRODUCTS_DATA = "SET_PRODUCTS_DATA";
export const SET_PRODUCTS_STOCK_DATA = "SET_PRODUCTS_STOCK_DATA";
export const SET_PRODUCTS_CATEGORY_DATA = "SET_PRODUCTS_CATEGORY_DATA";
export const SET_SWIFT_USERS_DATA = "SET_SWIFT_USERS_DATA";
