import { Tag } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { t } from "i18next";
import { StorageZone } from "../../../../Types/PrismaSwift";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

export interface ExpandedDataType {
  key: React.Key;
  Committee: any;
  name: string;
  upgradeNum: string;
}

export const StorageZonesColumns: (
  | ColumnGroupType<StorageZone>
  | ColumnType<StorageZone>
)[] = [
  {
    title: t("ID"),
    dataIndex: "storageZoneID",
    key: "storageZoneID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        {value && <QRCodeSVG value={value} size={40} />}
      </div>
    ),
  },
  {
    title: t("product_name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value?.replaceAll("_", " "),
    defaultSortOrder: "descend",
    width: "250px",
  },
  {
    title: t("storage_Zone_status"),
    dataIndex: "isFull",
    key: "isFull",

    render: (value) => (
      <Tag color={value ? "red" : "green"} className="uppercase">
        {t(value ? "full" : "available")}
      </Tag>
    ),
  },

  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value?.name} ${value?.lastName}`,
    width: "250px",
  },
  {
    title: t("warehouse_name"),
    dataIndex: "WareHouse",
    key: "WareHouse",
    render: (value) => value?.name.replaceAll("_", " "),
    width: "250px",
  },
  {
    title: t("committee"),
    dataIndex: "WareHouse",
    key: "Committee",
    render: (value) => value?.Committee?.nameLatin.replaceAll("_", " "),
    width: "250px",
  },
  {
    title: t("product_stock_count"),
    dataIndex: "_count",
    key: "_count",
    render: (value) => value.ProductStock,
    width: "100px",
  },
  {
    title: t("Pallet"),
    dataIndex: "_count",
    key: "_count",
    render: (value) => value.Pallet,
    width: "100px",
  },
  {
    title: t("Box"),
    dataIndex: "_count",
    key: "_count",
    render: (value) => value.Box,
    width: "100px",
  },
  {
    title: t("actions"),
    dataIndex: "productCategoryID",
    key: "productCategoryID",
    width: "100px",
    render: () => (
      <div className="w-full flex justify-center items-center"></div>
    ),
  },
  /*   {
*     title: t("products_count"),
      dataIndex: "_count",
      key: "_count",
      render: (value) => value.Product,
      width: "120px",
    },
    {
      title: t("InventoryTransaction"),
      dataIndex: "_count",
      key: "_count",
      render: (value) => value.InventoryTransaction,
      width: "120px",
    },
    {
      title: t("BinCard"),
      dataIndex: "_count",
      key: "_count",
      render: (value) => value.BinCard,
      width: "120px",
    },

    */
  /*  ,*/
];
