import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { useSelector } from "react-redux";
import { RootReducer } from "../../Store/reducers";
import { WarehousesContent } from "../../Components";

export function WarehousesPage() {
  const { t } = useTranslation();
  const warehouses = useSelector((state: RootReducer) => state.warehouses);

  return (
    <AppLayout>
      <PageLayout
        title={t("warehouses")}
        subtitle={
          warehouses.length
            ? `${warehouses.length} ${t("entries_found")}`
            : undefined
        }
      >
        <div className="h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4">
          <WarehousesContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
