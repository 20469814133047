import { TableColumnsType, Tag } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ProductStock } from "../../../../Types/PrismaSwift";

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}
export const WarehousesSubTableColumns: (
  t: any,
) => TableColumnsType<ExpandedDataType> = (t) => [
  {
    title: t("ID"),
    dataIndex: "storageZoneID",
    key: "storageZoneID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },
  {
    title: t("storage_Zone_name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value?.replaceAll("_", " "),
    defaultSortOrder: "descend",
    ellipsis: true,
  },
  {
    title: t("storage_Zone_status"),
    dataIndex: "isFull",
    key: "isFull",
    width: 100,
    render: (value) => (
      <Tag color={value ? "red" : "green"} className="uppercase">
        {t(value ? "full" : "available")}
      </Tag>
    ),
  },
  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value.name} ${value.lastName}`,
    ellipsis: true,
  },
  {
    title: t("product_count"),
    dataIndex: "Product",
    key: "Product",

    render: (value) => value?.length,
  },
  {
    title: t("product_stock_count"),
    dataIndex: "ProductStock",
    key: "ProductStock",
    render: (value: ProductStock[]) => {
      const unique: any[] = [];
      value.forEach((value) => {
        if (!unique.includes(value.productID)) {
          unique.push(value.productID);
        }
      });

      return unique.length;
    },
  },
];
