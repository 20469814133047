import React, { ReactNode, useState } from "react";
import { Button, Modal, Table } from "antd";
import { WarehousesColumns } from "./WarehousesColumns";
import { WarehousesSubTableColumns } from "./WarehousesSubTableColumns";
import { AddWarehouseModalContent } from "./AddWarehouseModalContent";
import { WarehouseProductsStockColumns } from "./WarehouseProductsStockColumns";
import { useWarehousesData } from "../../../../Hooks";
import { useTranslation } from "react-i18next";

export function WarehousesContent() {
  const { t } = useTranslation();
  const { warehouses, isLoading } = useWarehousesData();
  const [, setOpenModal] = useState<any>(false);
  const [openAddWarehouseModal, setOpenAddWarehouseModal] =
    useState<any>(false);
  let columns = WarehousesColumns(t, setOpenModal);
  const SubTableColumns = WarehousesSubTableColumns(t);

  columns = columns.map((column: any) => {
    if (column.key === "name" || column.key === "location") {
      const key = column.key;
      const filters = warehouses.map((row: any) => {
        return {
          text: row[key].replaceAll("_", " "),
          value: row[key],
        };
      });

      const uniqueFilter = filters.reduce((acc: any[], value: any) => {
        if (!acc.some((accVal: any) => accVal.value === value.value)) {
          acc.push(value);
        }
        return acc;
      }, []);

      return { ...column, filters: uniqueFilter };
    } else {
      return column;
    }
  });

  const tableSubColumns = WarehouseProductsStockColumns(t);
  const productStockExpendable = (data: any): ReactNode => {
    return (
      <Table
        key="ProductStock"
        rowKey="productStockID"
        size="middle"
        loading={isLoading}
        columns={tableSubColumns}
        dataSource={data.ProductStock}
      />
    );
  };

  const renderExpendable = (data: any): ReactNode => {
    return (
      <Table
        rowKey="storageZoneID"
        size="middle"
        loading={isLoading}
        columns={SubTableColumns}
        dataSource={data?.StorageZone}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: productStockExpendable,
        }}
      />
    );
  };

  return (
    <div className="w-full  overflow-scroll">
      <div className="w-full flex p-4 mb-4">
        <Button
          type="primary"
          className="!h-10 !bg-[#D63031] !hover:bg-[#E44C4D] !text-white"
          onClick={() => setOpenAddWarehouseModal(true)}
        >
          {t("add_new_warehouse")}
        </Button>
      </div>

      {openAddWarehouseModal && (
        <Modal
          title={t("add_new_warehouse")}
          open={openAddWarehouseModal}
          onCancel={() => setOpenAddWarehouseModal(false)}
          footer={null}
        >
          <AddWarehouseModalContent />
        </Modal>
      )}

      <Table
        rowKey="warehouseID"
        size="small"
        loading={isLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={columns}
        dataSource={warehouses}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: renderExpendable,
        }}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
      />
    </div>
  );
}
