import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { useSwiftSWR } from "../../../../Config";
import { Button, Input, InputRef, Modal, Space, Table } from "antd";
import { ProductsColumns, ProductsColumnsDataIndex } from "./ProductsColumns";
import { setProductsDataAction } from "../../../../Store/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { PrinterOutlined, SearchOutlined } from "@ant-design/icons";
import { ProductDetails } from "./ProductDetails";
import { useReactToPrint } from "react-to-print";
import ProductSmallTicketPrintable from "../../../Printables/ProductSmallTicketPrintable";
import ProductLargeTicketPrintable from "../../../Printables/ProductLargeTicketPrintable";
import { AddProductModalContent } from "./AddProductModalContent";

export function ProductsContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [products, setProducts] = useState<any>([]);
  const { data, isLoading, error } = useSwiftSWR("/inventory/get-products", {
    refreshInterval: 10000,
  });
  useEffect(() => {
    if (!isLoading && data) {
      const dataToShow = data.products;
      setProducts(dataToShow);
      dispatch(setProductsDataAction(dataToShow));
    }
    if (error) {
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
    // eslint-disable-next-line
  }, [data, isLoading, error]);

  /*const renderExpendable = (data: any): ReactNode => {
    return (
      <Table
        key={data.warehouseID}
        size="middle"
        loading={isLoading}
        columns={WarehousesColumns}
        dataSource={[]}
      />
    );
  };*/
  const renderDisExpendable = (data: any): ReactNode => {
    return (
      <p className={"p-8 w-full"}>
        <ProductDetails product={data} />
      </p>
    );
  };

  let columns = ProductsColumns(t);

  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: ProductsColumnsDataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: ProductsColumnsDataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${t(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),

    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const [isPrintModalOpen, setIsPrintModalOpen] = useState<boolean>(false);
  const [productToPrint, setProductToPrint] = useState<any>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const handlePrintModalCancel = () => {
    setIsPrintModalOpen(false);
  };
  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  const ProductSmallTicketRef = useRef(null);
  const ProductLargeTicketRef = useRef(null);

  const printSmallTicketHandler = useReactToPrint({
    content: () => ProductSmallTicketRef.current,
    onAfterPrint: () => handlePrintModalCancel(),
  });
  const printLargeTicketHandler = useReactToPrint({
    content: () => ProductLargeTicketRef.current,
    onAfterPrint: () => handlePrintModalCancel(),
  });

  columns = columns?.map((column: any) => {
    if (column.key === "unit") return column;
    if (column.key === "actions")
      return {
        ...column,
        render: (_, record) => (
          <div className="w-full flex justify-center items-center">
            <PrinterOutlined
              className="cursor-pointer"
              onClick={() => {
                setProductToPrint(record);
                setIsPrintModalOpen(true);
              }}
            />
          </div>
        ),
      };
    return {
      ...column,
      ...getColumnSearchProps(column.key as ProductsColumnsDataIndex),
    };
  });

  return (
    <div className="w-full  overflow-scroll">
      {!isLoading && (
        <div className="w-full flex p-4 mb-4">
          <Button
            type="primary"
            className="!h-10 !bg-[#D63031] !hover:bg-[#E44C4D] !text-white"
            onClick={() => setIsAddModalOpen(true)}
          >
            {t("add_new_product")}
          </Button>
        </div>
      )}
      {productToPrint && (
        <div className="hidden">
          <div ref={ProductSmallTicketRef}>
            <ProductSmallTicketPrintable productToPrint={productToPrint} />
          </div>
          <div ref={ProductLargeTicketRef}>
            <ProductLargeTicketPrintable productToPrint={productToPrint} />
          </div>
        </div>
      )}
      <Modal
        title={t("print_product_ticket")}
        open={isPrintModalOpen}
        onCancel={handlePrintModalCancel}
        footer={null}
      >
        <div className="w-full  flex  justify-evenly p-5">
          <Button
            type="primary"
            className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757]"
            onClick={printSmallTicketHandler}
          >
            {t("print_ticket_size_small")}
          </Button>
          <Button
            type="primary"
            className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757]"
            onClick={printLargeTicketHandler}
          >
            {t("print_ticket_size_large")}
          </Button>
        </div>
      </Modal>
      {isAddModalOpen && (
        <Modal
          title={t("add_new_product")}
          open={isAddModalOpen}
          onCancel={handleAddModalCancel}
          footer={null}
          width={600}
        >
          <AddProductModalContent />
        </Modal>
      )}
      <Table
        rowKey="productID"
        size="small"
        loading={isLoading}
        rootClassName="h-2/3 min-h-2/3 "
        columns={columns}
        dataSource={products}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: renderDisExpendable,
        }}
        bordered={true}
        tableLayout={"auto"}
        sticky={false}
        showHeader={true}
      />
    </div>
  );
}
