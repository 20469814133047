import { AuthLayout } from "../../Layouts";
import { LoginForm } from "../../Components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoginUserLogged from "../../Components/Features/Auth/LoginUserLogged";

export const LoginPage = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const isAuthenticated = useSelector(
    (state: any) => state.authentication.isAuthenticated,
  );
  return (
    <AuthLayout>
      <div className="w-full h-fit  flex flex-col my-auto">
        <h3 className="text-[#1E232C] text-4xl font-semibold">
          {t("connect_to_swift")}
        </h3>
        <span className="text-[#D63031] text-3xl font-semibold uppercase mb-10">
          {t("connect_to_swift_now")}
        </span>

        {token && isAuthenticated ? <LoginUserLogged /> : <LoginForm />}
      </div>
    </AuthLayout>
  );
};
