import React from "react";
import { Table } from "antd";
import { useSwiftUsersData } from "../../../../Hooks/useSwiftUsersData";
import { SwiftUsersColumns } from "./SwiftUsersColumns";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

function SwiftUsersContent() {
  const { isSwiftUsersLoading, swiftUsers } = useSwiftUsersData();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  moment.locale(language);

  let columns = SwiftUsersColumns(t, moment);

  columns = columns.map((column: any) => {
    if (
      column.key === "name" ||
      column.key === "lastName" ||
      column.key === "email" ||
      column.key === "isActive" ||
      column.key === "isVerified" ||
      column.key === "profile"
    ) {
      const key = column.key;
      const filters = swiftUsers.map((row: any) => {
        return {
          text: row[key],
          value: row[key],
        };
      });

      const uniqueFilter = filters.reduce((acc: any[], value: any) => {
        if (!acc.some((accVal: any) => accVal.value === value.value)) {
          acc.push(value);
        }
        return acc;
      }, []);

      return { ...column, filters: uniqueFilter };
    } else {
      return column;
    }
  });

  return (
    <div className="w-full  overflow-scroll">
      <div className="w-full flex p-4 mb-4">
        <Table
          rowKey="userID"
          size="small"
          loading={isSwiftUsersLoading}
          rootClassName="h-2/3 min-h-2/3 "
          columns={columns}
          dataSource={swiftUsers}
          pagination={{ pageSize: 8 }}
          tableLayout={"auto"}
          sticky={false}
        />
      </div>
    </div>
  );
}

export default SwiftUsersContent;
