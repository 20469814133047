import {
  CHANGE_OTP_STATUS,
  FORGOT_PASSWORD,
  GENERATE_OTP_CODE,
  USER_LOGIN,
  USER_LOGOUT,
} from "../actionTypes";
import { UserTypes } from "../../Types/UserTypes";

export interface AuthState {
  user: UserTypes | null;
  isAuthenticated: boolean;
  token: string | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  token: null,
};

const authReducer = (
  state: AuthState = initialState,
  { type, payload }: { type: string; payload: any },
): AuthState => {
  switch (type) {
    case USER_LOGIN:
      return {
        user: payload,
        isAuthenticated: true,
        token: payload.token,
      };
    case CHANGE_OTP_STATUS:
      return {
        user: {
          ...(state.user as UserTypes),
          ...payload,
        },
        isAuthenticated: state.isAuthenticated,
        token: state.token,
      };
    case GENERATE_OTP_CODE:
      return {
        user: {
          ...(state.user as UserTypes),
          ...payload,
        },
        isAuthenticated: state.isAuthenticated,
        token: state.token,
      };
    case USER_LOGOUT:
      return initialState;

    case FORGOT_PASSWORD:
      return {
        user: payload,
        isAuthenticated: true,
        token: payload.token,
      };
    default:
      return state;
  }
};

export default authReducer;
