import { Routes } from "react-router-dom"; /*export const sentry = Sentry;
const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  sendClientReports: false,
  integrations: [
    new sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});*/

/*export const sentry = Sentry;
const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  sendClientReports: false,
  integrations: [
    new sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});*/
export const RenderRoutes = Routes;
