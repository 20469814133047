import React, { SVGProps } from "react";

function SwiftLogoColored(props: SVGProps<any>) {
  return (
    <svg
      width="87"
      height="91"
      viewBox="0 0 87 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0V90.9641H86.9584V0H0ZM6.75629 26.7009C6.75629 17.147 14.161 9.40755 23.2914 9.40755H46.1122V43.9995H23.2914C14.161 43.9943 6.75629 36.2497 6.75629 26.7009ZM49.2602 81.5618H26.4342V46.9698H49.255C58.3854 46.9698 65.7901 54.7144 65.7901 64.2631C65.7953 73.8224 58.3906 81.5618 49.2602 81.5618ZM74.7479 81.5618C71.7358 81.5618 69.299 79.0098 69.299 75.8618C69.299 72.7137 71.7411 70.1618 74.7479 70.1618C77.76 70.1618 80.1969 72.7137 80.1969 75.8618C80.1969 79.0098 77.7548 81.5618 74.7479 81.5618Z"
        fill="#D63130"
      />
    </svg>
  );
}

export default SwiftLogoColored;
