import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { AddProductStock } from "../../Components/Features/Inventory/ProductsStock/AddProductStock";

export function AddProductStockPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("add_product_stock")}>
        <div className="min-h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4 ">
          <AddProductStock />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
