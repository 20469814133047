import { User } from "../../Types/PrismaSwift";
import { SET_SWIFT_USERS_DATA, USER_LOGOUT } from "../actionTypes";

export type SwiftUsersState = User[] | [];

const initialState: User[] | [] = [];
const SwiftUsersReducer = (
  state: SwiftUsersState = initialState,
  { type, payload }: { type: string; payload: User[] | [] },
): SwiftUsersState => {
  switch (type) {
    case USER_LOGOUT:
      return initialState;
    case SET_SWIFT_USERS_DATA:
      return payload;

    default:
      return state;
  }
};
export default SwiftUsersReducer;
