import React, { SVGProps } from "react";

function LogoTrcSimple(props: SVGProps<any>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 582 163" {...props}>
      <path
        fill="#DC1018"
        d="M461.5,127.9c0.8,0.1,1.7,0,2.3,0.4c6.6,4,13.7,5.9,21.4,6.5c9.8,0.7,19.2-0.6,27.8-5.1
	c15.5-8.1,25.3-20.6,28.6-38c4.4-23.1-7.1-45.7-27.6-56.3c-10.1-5.2-20.9-7.1-32.4-5.2c-6.9,1.1-13.3,3.4-19.8,6.5
	c0.6-0.7,1.2-1.4,1.8-2.1c0.8-0.8,1.6-1.5,2.5-2.2c11.9-9.2,25.2-14.4,40.5-14.4c17.6-0.1,32.7,6.1,45.3,18
	c10.8,10.3,17.4,23.1,19.2,38.2c2.7,23-5.2,41.9-22.3,57c-9.5,8.4-20.8,13.2-33.5,14.8c-20.6,2.7-38-3.8-53.2-17.4
	c-0.2-0.2-0.3-0.5-0.4-0.8C461.6,127.8,461.5,127.9,461.5,127.9z"
      />
      <path
        fill="#080808"
        d="M29.2,77.6c-1.5-0.5-2.7,0.4-4.1,0.8C18,80,12.6,76.7,11.4,69.9c-0.3-1.5-0.2-3.2,0-4.7
	c0.7-4.6,2.5-8.7,5.4-12.4c0.2-0.3,0.8-0.4,1.1-0.6c0.9,0.7,0.7,1.5,0.4,2.2c-0.8,1.9-0.7,4-0.9,6c-0.3,5.4,4,9.7,9.9,7.2
	c-0.6-2.2-0.6-2.2-5.9-6.3c0.4-3.6,2.1-6.7,3.7-9.6c1.4-0.9,2.7-0.6,3.9-0.6c10.5,0,20.9-0.1,31.4,0c2.4,0,4.7-0.2,6.3-1.8
	c1.5-0.1,2.1,1.4,3.5,1.1c0.9-0.6,1.7-1.3,2.7-2c1,0.7,2,1.4,2.7,1.9c1.5,0.3,2-0.8,2.9-1c0.2,0,0.4,0,0.5,0.1
	c4.6,3.4,9.8,1.4,14.7,1.7c6,0.3,12,0.1,17.7,0.1c1.4-2.2-0.7-3.3-0.4-5.6c0.4-1.5,2.3-2.8,3.6-4.8c2.5,2.5,2.2,5.6,2.6,8.4
	c0.4,3.1,0.2,6.3,0.1,9.4c-0.1,2.9,0.6,5.9-0.7,9.2c-5.3,0-10.7,0-16,0c-5.4,0-10.8,0.3-16.1-0.1c-5.3-0.3-10.7-0.3-16-0.3
	c-5.3,0-10.7,0.4-16,0.3c-5.3-0.1-10.7,0.5-16.1-0.5c-0.8,3.4-2.9,6.3-5.9,8.5c-0.6,0.5-1.3,0.9-1.2,1.8L29.2,77.6z"
      />
      <path
        fill="#080808"
        d="M246.7,50.9c3.3-1.8,3.5-6.9,8-7.4c2.3,0.7,3.1,2.8,4.1,4.5c1.4,2.3,3.1,3.3,5.8,3.2c3.8-0.1,7.6,0,11.7,0
	c-0.8-2.1-2.7-2.1-3.4-2.7c-2.2-0.4-3,1.3-4.4,1.5c-0.5-0.2-0.7-0.6-0.7-1.1c-0.3-2.6,0.3-5,2.1-7c2.1-2.2,4.3-2.6,6.8-0.9
	c1.8,1.2,3.4,2.9,4.3,4.9c1.6,3.3,3.9,5.7,8.4,5.4c-0.6,5.9-2.5,11-4.2,16.4c-9,0-17.6,0-26.6,0c-1.3,1.3-1.6,4.2-4.7,4.4
	c-2.5-0.4-4-2.7-6.1-3.9c-4.2-0.4-34.8-0.4-39.1,0.1c-1.4,1.9-1.8,4.4-3.1,6.6c-2.7,4.4-8.1,5-11.7,1.7c-1.8-1.6-3.4-3.3-3.9-5.9
	c2.7-1.5,5.9,0.3,8.5-1.7c2.5-1.8,4.4-4,5.7-6.8c-1.2-3.1-3.9-5.4-4.7-8.9c1.2-2,2.2-4.3,4.1-5.9c2,0.3,2.1,2.2,3,3.6
	C219.9,50.9,233.3,50.9,246.7,50.9z M252.6,51.4c0.4,1.2,1.2,1.7,2.4,1.5C255.1,51,255.1,51,252.6,51.4z"
      />
      <path
        fill="#080808"
        d="M413.2,67.9c-7.8,0-14.8-0.1-21.8,0c-2.5,0-4.7-0.3-6.6-2c-1,0.6-1.9,1.2-3,2c-3.6-0.5-7.6,0.8-11.4-1.1
	c-0.7-1.4-1.5-2.7-2.3-4.2c-1.1,1.6-2.1,3.2-3.7,4.2c-1.9,1.2-4.1,1.7-6.2,0.7c-2.3-1.1-3.1-2.9-3-5.4c0.1-3.9,0-7.8,0-12.3
	c2.6,1.3,4.9,2.4,7.4,1.2c-0.5-2.7-0.5-2.7-3.1-6.4c-0.8-1-1.9,0.7-2.7-0.3c-1.3-1.7-2.7-3.5-4-5.2c-0.2-1.6,0.7-3,1.1-4.5
	c0.5-1.5,1.4-2.8,2.2-4.4c3.5,1.8,4.5,5.3,6.3,8.1c1.8,2.8,3.2,6,4.8,9.3c2.6-4.6,0.7-9.3,1.8-13.9c1-1.4,2.2-2.7,4.5-3.7
	c0,3.8-0.1,7.2,0,10.7c0.2,3.4-0.9,6.9,1,10.2c1.9,0,3.8,0,6,0c-0.1-3.3,1.8-6.4,1.9-9.6c0-3,1.1-4.6,3-6.1c2.4-0.7,4.1,0.4,5.7,1.8
	c4.3,3.7,7.1,8.3,8.3,13.9c2.5,0.1,4.8,0.3,7.3-0.2c0.7-5-1.5-9.7-1.2-14.8c1-2.2,2.8-4.1,5.1-5.8c2,2.7,3.1,5.4,2.2,8.4
	c-0.6,1.9-0.5,3.6-0.2,5.5C413.7,51.9,413.1,59.7,413.2,67.9z M393.9,51.2c-0.7-1.3-1.1-2.6-2.8-1.9c-0.7,1-1,2.1-0.6,3.3
	C392.2,53.8,393,52.7,393.9,51.2z M386.7,45.5c-2,2.3-2,2.3-0.9,5C386.3,48.7,387.7,47.7,386.7,45.5z"
      />
      <path
        fill="#080808"
        d="M144.5,51c2.2-0.8,2.4-2.9,3.3-4.7c2.4,0.7,1.6,4.1,3.9,4.5c3.7,0,7.5,0,11.4,0c0.3-4.8-1.2-9.1-1.6-14.2
	c0.6-2.5,2.7-4.5,5.5-6.5c1.9,3,3.2,5.7,1.8,9.2c-0.8,1.9-0.3,4.1,0.1,6.1c1.3,7.2,0.3,14.4,0.7,21.6c-3.2,1.3-6.3,0.6-9.2,0.7
	c-2.8,0.1-5.7,0-8.8,0c-1.2-0.1-2.1-1.6-3.6-1.9c-1,0.6-2.1,1.3-3.1,2c-2.9,0-5.7,0-8.6,0c-1.2,1.2-1.2,2.8-1.9,4.2
	c-1.1,2-2.3,3.8-4.2,5c-4.9,3.2-10.8,0.3-11.7-5.8c7-0.7,7.3-0.8,11.1-3.9c-2.1,0-3.9,0-5.6-0.5c-1.9-1.3-2.8-3.2-2.8-5.6
	c-0.1-6.4,0.9-12.5,3.8-18.2c1.4-2.9,1.7-3,5.1-3.4c4.2,2.5,4.5,7.1,6,11.2C139,51,141.8,51,144.5,51z M127.2,48
	c0.4,2,1.4,3.1,3.7,3C129.8,47.3,129.8,47.3,127.2,48z"
      />
      <path
        fill="#080808"
        d="M342.7,35.3c1.4-2.2,2.9-3.9,5.2-5.3c2,2.4,3,5.2,2.2,7.9c-0.7,2.4-0.4,4.6-0.2,6.9
	c0.6,6.1,1.6,12.1,1.5,18.2c0,3.2-0.6,6.3-2.1,9.1c-2,3.7-4.9,6-9.5,5.7c-2.8-0.1-4.8-1.3-6.5-3.3c-5.4-6.3-4.8-16.8,1.1-23
	c0.9,0.5,0.7,1.3,0.7,2c0.1,1.3-0.1,2.5,0.2,3.7c1,4.5,4.7,5.6,8.1,2.6c0.5-0.4,0.8-1,1.3-1.6C345.6,50.6,342.5,43,342.7,35.3z"
      />
      <path
        fill="#080808"
        d="M293.1,67.9c-1.2-1.5-1.4-2.8-1.3-4.3c0.1-2,0.1-4.1,0-6.1c-0.2-3.2,0.8-5.7,3.5-7.7c1.4-1,2.5-2.3,3.9-3.6
	c-1.5-2.2-2.4-4.3-5.3-5c-1.9-0.4-2.8-2.4-4-3.9c0.4-2.5,1.3-4.8,3-7c3.4,1.8,4.5,5.7,7.8,7.7c1.3-1.5-0.1-3.4,0.6-4.9
	c2.4-2.1,2.4-2.1,5.7-2.5c1,2.9,2.2,5.6,0.4,8.8c-1,1.8-1.3,4.1-2,6.4c0.9,2.2,2.7,4.2,2.8,6.7c0.1,2.5,0.1,5,0,7.5
	c-0.1,2.4,0.4,5-0.5,7.5c-2.5,0.8-5,0.3-7.5,0.4C297.9,68,295.5,67.9,293.1,67.9z M302.8,51.6c0.3-0.7,0-1.2-0.6-1.3
	c-0.6-0.1-1.1,0.4-1,1.1C301.7,51.9,302.3,52.1,302.8,51.6z"
      />
      <path
        fill="#080808"
        d="M172.6,34.9c2.4-1.1,4.1-2.6,6.6-2.9c1.5,3,1.4,6.3,2.1,9.6c-1.9,1.4-2.1,3.3-2,5.4c0.1,5.2,0,10.4,0,15.3
	c-1.8,2.2-2.8,4.6-5.7,6.1C174,57,172.4,46.1,172.6,34.9z"
      />
      <path
        fill="#080808"
        d="M320,41.5c-0.6,0.9-1.3,1.7-2,2.7c0,6.1,0,12.2,0,18.2c-1.5,2.3-2.6,4.5-5.6,6.1c0.3-11.3-0.9-22-1.1-32.9
	c1.9-1.8,3.9-3.1,6.7-3.7C319.3,35,319.8,38.2,320,41.5z"
      />
      <path
        fill="#080808"
        d="M417.1,68.6c-0.1-11.5-0.5-22.2-1.3-32.9c2.1-1.9,4.2-3.5,7.2-3.6c0.8,3.1,1.6,6.1,1.6,9.4
	c-0.6,0.8-1.2,1.7-2,2.9c0,4.9-0.1,10.1,0,15.3C422.8,63.6,420.7,66,417.1,68.6z"
      />
      <path
        fill="#080808"
        d="M141.1,40.3c1-1.9,1.8-3.4,3.3-4.4c1.8-0.1,2,1.9,3.6,2.2c1.5,0.1,1.7-2,3.5-2.3c1.6,0.8,1.9,2.9,3.6,4
	c-1,2.1-2.3,3.5-3.7,4.8c-1.7-0.4-1.9-2.3-3.3-2.9c-1.6,0.4-1.5,2.8-3.6,2.8C143.1,43.5,142.2,41.9,141.1,40.3z"
      />
      <path
        fill="#080808"
        d="M29.1,77.7c2.3-0.4,2.8,2,4.9,3.2c0.8-1.1,1.5-2.3,2.4-3.6c2.1,1.5,3.3,3.1,4.1,5.1
	c-1.1,1.4-2.3,2.9-3.6,4.6c-1-1.2-2-2.4-3.1-3.6c-1,1.1-1.9,2.1-2.9,3.1c-2.2-0.8-2.9-2.7-3.7-3.9c0.1-2.3,2.7-2.9,2.2-5L29.1,77.7z
	"
      />
      <path
        fill="#080808"
        d="M297,25.3c0.3,1.5-0.8,2.2-1.7,3c-1.9,0.1-3.8,0.6-5.6-1.4c1.8-2.4,0-6.9,4.8-7.7c1.3,0.6,2.1,1.5,1.9,3
	c-0.1,1-1.4,1-1.9,1.8C294.9,25.3,296.6,24,297,25.3z"
      />
      <path
        fill="#080808"
        d="M106,38.1c1.4-1.8,2.5-3.3,3.8-4.9c2.1,1.1,2.5,3.3,4.1,4.7c-1.2,1.6-2.3,3.1-3.5,4.7
	C108.1,42,107.6,39.7,106,38.1z"
      />
      <path
        fill="#DC1018"
        d="M461.6,127.8c-0.2-0.1-0.5-0.2-0.8-0.4c0.7-0.5,0.7,0,0.7,0.4L461.6,127.8z"
      />
      <path
        fill="#FDFDFD"
        d="M252.6,51.4c2.4-0.4,2.4-0.4,2.4,1.5C253.8,53.1,253,52.6,252.6,51.4z"
      />
      <path
        fill="#FDFDFD"
        d="M393.9,51.2c-0.9,1.5-1.7,2.6-3.5,1.4c-0.4-1.2-0.1-2.3,0.6-3.3C392.8,48.6,393.1,49.9,393.9,51.2z"
      />
      <path
        fill="#FDFDFD"
        d="M386.7,45.5c1,2.2-0.4,3.2-0.9,5C384.7,47.8,384.7,47.8,386.7,45.5z"
      />
      <path
        fill="#FDFDFD"
        d="M127.2,48c2.6-0.7,2.6-0.7,3.7,3C128.7,51,127.6,50,127.2,48z"
      />
      <path
        fill="#FDFDFD"
        d="M302.8,51.6c-0.6,0.5-1.1,0.3-1.6-0.2c-0.1-0.7,0.4-1.2,1-1.1C302.9,50.4,303.2,51,302.8,51.6z"
      />
      <g>
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M18.3,102.4h-6.9v-4h18.7v4h-6.9v18.7h-4.9V102.4z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M38.4,98.3v13.6c0,4.4,2.2,5.7,4.7,5.7c2.8,0,4.6-1.5,4.6-5.7
		V98.3h4.9v13.3c0,7.8-4.6,9.8-9.6,9.8c-5.1,0-9.6-2.1-9.6-9.7V98.3H38.4z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M57.8,121.1V98.3h6.1c2.8,5.1,8.1,14.3,9.1,16.8h0.1
		c-0.3-2.4-0.3-6.3-0.3-10.1v-6.7h4.5v22.8h-5.8c-2.4-4.4-8.3-15-9.4-17.7h-0.1c0.2,2,0.2,6.7,0.2,10.7v6.9H57.8z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M87.7,98.3v22.8h-4.9V98.3H87.7z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M96.3,114.4c0.5,2.3,2.2,3.3,4.8,3.3c2.6,0,3.8-1,3.8-2.7
		c0-1.9-1.1-2.7-5-3.6c-6.2-1.5-7.7-3.7-7.7-6.8c0-4,3-6.7,8.4-6.7c6,0,8.4,3.2,8.8,6.6h-5c-0.2-1.4-1-2.9-3.9-2.9
		c-2,0-3.2,0.8-3.2,2.4c0,1.6,1,2.2,4.6,3.1c6.6,1.6,8.1,4,8.1,7.2c0,4.2-3.1,7-9.2,7c-5.8,0-8.8-2.8-9.4-7H96.3z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M118.4,98.3v22.8h-4.9V98.3H118.4z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M128.1,115.8l-1.8,5.3h-4.8l7.7-22.8h6.2l8.1,22.8h-5.2
		l-1.9-5.3H128.1z M135.3,111.8c-1.6-4.8-2.6-7.9-3.2-9.9h0c-0.6,2.2-1.7,5.8-3,9.9H135.3z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M146.4,121.1V98.3h6.1c2.8,5.1,8.1,14.3,9.1,16.8h0.1
		c-0.3-2.4-0.3-6.3-0.3-10.1v-6.7h4.5v22.8h-5.8c-2.4-4.4-8.3-15-9.4-17.7h-0.1c0.2,2,0.2,6.7,0.2,10.7v6.9H146.4z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M184.1,112v9.1h-4.8V98.3h10c5,0,7.6,2.7,7.6,6.2
		c0,3.1-1.7,4.7-3.3,5.4c1.1,0.4,2.8,1.6,2.8,5.7v1.1c0,1.5,0,3.3,0.3,4.3h-4.7c-0.4-1-0.5-2.7-0.5-5.1v-0.3c0-2.5-0.6-3.7-4.3-3.7
		H184.1z M184.1,108.1h4.1c2.7,0,3.7-1,3.7-2.9c0-1.9-1.2-2.9-3.6-2.9h-4.2V108.1z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M216.9,111.3h-10.9v5.8h12l-0.6,4h-16.2V98.3h16.1v4h-11.4
		v4.9h10.9V111.3z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M221.6,98.3h9c6.6,0,11,4.4,11,11.1c0,6.6-4.2,11.7-11.2,11.7
		h-8.8V98.3z M226.5,117.1h3.7c4.4,0,6.3-3.2,6.3-7.7c0-3.7-1.8-7.1-6.3-7.1h-3.7V117.1z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M273.2,114.1c-0.9,4.2-4.1,7.3-9.8,7.3
		c-7.2,0-10.6-5.1-10.6-11.6c0-6.2,3.5-11.8,10.9-11.8c6.1,0,9,3.6,9.6,7.4h-4.9c-0.5-1.8-1.7-3.5-4.8-3.5c-4.1,0-5.6,3.7-5.6,7.8
		c0,3.8,1.4,7.9,5.8,7.9c3.2,0,4.2-2.2,4.7-3.5H273.2z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M281.8,112v9.1h-4.8V98.3h10c5,0,7.6,2.7,7.6,6.2
		c0,3.1-1.7,4.7-3.3,5.4c1.1,0.4,2.8,1.6,2.8,5.7v1.1c0,1.5,0,3.3,0.3,4.3h-4.7c-0.4-1-0.5-2.7-0.5-5.1v-0.3c0-2.5-0.6-3.7-4.3-3.7
		H281.8z M281.8,108.1h4.1c2.7,0,3.7-1,3.7-2.9c0-1.9-1.2-2.9-3.6-2.9h-4.2V108.1z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M314.6,111.3h-10.9v5.8h12l-0.6,4H299V98.3h16.1v4h-11.4v4.9
		h10.9V111.3z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M323,114.4c0.5,2.3,2.2,3.3,4.8,3.3c2.6,0,3.8-1,3.8-2.7
		c0-1.9-1.1-2.7-5-3.6c-6.2-1.5-7.7-3.7-7.7-6.8c0-4,3-6.7,8.4-6.7c6,0,8.4,3.2,8.8,6.6h-5c-0.2-1.4-1-2.9-3.9-2.9
		c-2,0-3.2,0.8-3.2,2.4c0,1.6,1,2.2,4.6,3.1c6.6,1.6,8.1,4,8.1,7.2c0,4.2-3.1,7-9.2,7c-5.8,0-8.8-2.8-9.4-7H323z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M359.8,114.1c-0.9,4.2-4.1,7.3-9.8,7.3
		c-7.2,0-10.6-5.1-10.6-11.6c0-6.2,3.5-11.8,10.9-11.8c6.1,0,9,3.6,9.6,7.4h-4.9c-0.5-1.8-1.7-3.5-4.8-3.5c-4.1,0-5.6,3.7-5.6,7.8
		c0,3.8,1.4,7.9,5.8,7.9c3.2,0,4.2-2.2,4.7-3.5H359.8z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M379.2,111.3h-10.9v5.8h12l-0.6,4h-16.2V98.3h16.1v4h-11.4
		v4.9h10.9V111.3z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M383.9,121.1V98.3h6.1c2.8,5.1,8.1,14.3,9.1,16.8h0.1
		c-0.3-2.4-0.3-6.3-0.3-10.1v-6.7h4.5v22.8h-5.8c-2.4-4.4-8.3-15-9.4-17.7h-0.1c0.2,2,0.2,6.7,0.2,10.7v6.9H383.9z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M413.7,102.4h-6.9v-4h18.7v4h-6.9v18.7h-4.9V102.4z"
        />
      </g>
    </svg>
  );
}

export default LogoTrcSimple;
