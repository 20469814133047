import React, { useEffect, useState } from "react";
import { Popover, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../../../Config";
import { LoginActivitiesT } from "../../../Types/UserTypes";
import moment from "moment";
import { ChromeFilled } from "@ant-design/icons";

function ActivityHistoryContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  moment.locale(language);

  const { data, isLoading } = useSwiftSWR("/users/activity");
  const [loginActivity, setLoginActivity] = useState<LoginActivitiesT[] | []>(
    [],
  );

  useEffect(() => {
    if (data) {
      setLoginActivity(data.history);
    }
  }, [data]);

  const columns = [
    {
      title: t("ip"),
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("continent"),
      dataIndex: "continent",
      key: "continent",
    },
    {
      title: t("region"),
      dataIndex: "region",
      key: "region",
    },
    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: string) => <p>{moment(new Date(data)).format("llll")}</p>,
    },
    {
      title: t("browser"),
      dataIndex: "browser",
      key: "browser",
      render: (browser: string) => {
        return (
          <div className="w-full h-full flex justify-center items-center">
            <Popover content={browser}>
              <ChromeFilled />
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-8 flex justify-center items-center">
      <Table
        className="w-full "
        dataSource={loginActivity}
        columns={columns}
        size="small"
        showSorterTooltip={true}
        loading={isLoading}
        pagination={{ pageSizeOptions: ["10"] }}
      />
    </div>
  );
}

export default ActivityHistoryContent;
