import React from "react";
import { capitalizeFirstLetter } from "../Utils";

interface PageLayoutProps {
  title?: string;
  subtitle?: string;
  children: any;
}

function PageLayout({ title, subtitle, children }: PageLayoutProps) {
  return (
    <div className="p-5 md:p-10 w-full max-w-full overflow-y-auto flex flex-col">
      <div className="w-full mb-5">
        {title && (
          <h2
            className=" font-bold text-lg md:text-3xl w-full text-[#1D1D1F]"
            style={{ fontFamily: "Inter" }}
          >
            {capitalizeFirstLetter(title)}
          </h2>
        )}

        {subtitle && (
          <h2
            className=" font-normal text-xs md:text-sm w-full text-[#6E6E72]"
            style={{ fontFamily: "Inter" }}
          >
            {subtitle}
          </h2>
        )}
      </div>
      {children}
    </div>
  );
}

export default PageLayout;
