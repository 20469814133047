import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSwiftSWR } from "../Config";
import { setProductsStockDataAction } from "../Store/actions/ProductStockActions";
import { toast } from "react-toastify";
import { t } from "i18next";
import { ProductStock } from "../Types/PrismaSwift";

export const useProductStockData = () => {
  const dispatch = useDispatch();

  const [productsStock, setProductsStock] = useState<ProductStock[] | []>([]);

  const { data, isLoading, error } = useSwiftSWR(
    "/inventory/get-product-stocks",
    {
      refreshInterval: 5000,
    },
  );

  useEffect(() => {
    const handleProductStock = () => {
      if (!isLoading && data) {
        const dataToShow = data["product-stocks"];
        setProductsStock(dataToShow);
        dispatch(setProductsStockDataAction(dataToShow));
      }
    };

    const handleProductStockError = () => {
      if (error) {
        toast.error(
          t(
            error?.response.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleProductStock();
    handleProductStockError();

    // eslint-disable-next-line
  }, [data, isLoading, error, dispatch]);

  return {
    productsStock,
    isProductsStockLoading: isLoading,
    ProductsStockError: error,
  };
};
