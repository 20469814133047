import React, { useState } from "react";
import { Dropdown, DropdownProps, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { saveSelectedLanguage } from "../../Utils";

export const LanguageToggle = (props: DropdownProps) => {
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState(i18n.language);

  const changeLanguage = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    setCurrentLang(selectedLanguage);
    saveSelectedLanguage(selectedLanguage);
  };

  const items: MenuProps["items"] = [
    {
      label: "English",
      key: "en",
      onClick: (e) => changeLanguage(e.key),
    },
    {
      label: "Français",
      key: "fr",
      onClick: (e) => changeLanguage(e.key),
    },
    {
      label: "العربية",
      key: "ar",
      onClick: (e) => changeLanguage(e.key),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} {...props}>
      <div className="cursor-pointer">
        <Space className=" text-sm">
          {currentLang === "en"
            ? "English"
            : currentLang === "ar"
            ? "العربية"
            : "Français"}
          <DownOutlined rev={undefined} />
        </Space>
      </div>
    </Dropdown>
  );
};
