import React, { MouseEventHandler } from "react";
import { Badge, Card } from "antd";

interface DashboardSquareCardProps {
  count?: number;
  Icon: any;
  title: string;
  cb?: MouseEventHandler<HTMLDivElement> | undefined;
}
function DashboardSquareCard({
  count = 0,
  Icon,
  title,
  cb,
}: DashboardSquareCardProps) {
  return (
    <Card
      bordered={false}
      className={` hidden md:flex flex-col w-full md:w-[250px]  ${
        cb ? "cursor-pointer" : ""
      }`}
      onClick={cb}
    >
      <div className="w-full flex justify-center mb-4  ">
        <Badge count={count}>
          <Icon className="text-lg md:text-4xl text-gray-500" />
        </Badge>
      </div>
      <p
        className="w-full mt-5 text-center font-medium text-sm md:text-lg "
        style={{ fontFamily: "Roboto" }}
      >
        {title}
      </p>
    </Card>
  );
}

export default DashboardSquareCard;
