import React from "react";
import OtpInput, { OTPInputProps } from "react-otp-input";

interface OtpCustomInputProps extends OTPInputProps {
  inputClassName?: string;
}

function OtpCustomInput({
  inputClassName = " !w-14 !h-14",
  ...props
}: Omit<OtpCustomInputProps, "renderInput">) {
  return (
    <OtpInput
      containerStyle={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
      renderInput={(props: any) => (
        <input
          {...props}
          className={" !rounded !bg-[#E8ECF4] number-input " + inputClassName}
        />
      )}
      {...props}
    />
  );
}

export default OtpCustomInput;
