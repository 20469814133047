import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiftSWR } from "../Config";
import { toast } from "react-toastify";

export const useBoxesData = (refreshInterval: number | undefined = 10000) => {
  const { t } = useTranslation();

  const [boxes, setBoxes] = useState<any[] | []>([]);

  const {
    data: boxData,
    isLoading: isBoxesLoading,
    error: boxReqError,
  } = useSwiftSWR("/inventory/get-boxes", {
    refreshInterval,
  });

  useEffect(() => {
    const handlePalletsData = () => {
      if (!isBoxesLoading && boxData) {
        const dataToShow = boxData.boxes;
        setBoxes(dataToShow);
      }
    };

    const handlePalletsError = () => {
      if (boxReqError) {
        toast.error(
          t(
            boxReqError?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handlePalletsData();
    handlePalletsError();

    // eslint-disable-next-line
  }, [boxData, isBoxesLoading, boxReqError]);

  return { boxesData: boxes, isBoxesLoading, boxReqError };
};
