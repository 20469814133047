import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const DashboardGreating = () => {
  const { t } = useTranslation();

  const user = useSelector((state: any) => state.authentication.user);

  function getGreeting() {
    const currentHour = moment().hours();

    if (currentHour >= 5 && currentHour < 12) {
      return t("good_morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("good_afternoon");
    } else {
      return t("good_evening");
    }
  }
  return (
    <div className="w-full flex flex-col justify-cente items-center">
      <h3
        className="font-normal text-sm md:text-lg text-[#1E232C] text-center"
        style={{ fontFamily: "Inter" }}
      >
        {moment()
          .format("dddd, MMMM Do")
          .replace(/\b\w/g, (l) => l.toUpperCase())}
      </h3>
      <h3
        className="font-semibold text-xl md:text-3xl text-[#1E232C] mt-2 text-center "
        style={{ fontFamily: "Urbanist" }}
      >
        {getGreeting()}, {user?.name}
      </h3>
      <div className="bg-[#1E232C] rounded-2xl h-[58px] w-full lg:w-1/2 md:max-w-[650px] lg:max-w-[450px] mt-5  flex  p-2 text-white justify-evenly items-center text-xs md:text-sm font-normal">
        <h3 className="px-2 md:px-5 text-center">My week</h3>
        <span className="border-r border-gray-400 h-2/3"></span>
        <h3 className="  px-2 md:px-5 text-center ">0 Tasks</h3>
        <span className="border-r border-gray-400  h-2/3"></span>
        <h3 className="  px-2 md:px-5 text-center">0 Tasks completed</h3>
      </div>
    </div>
  );
};
