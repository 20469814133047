import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { ProductsStockContent } from "../../Components";
import { useSelector } from "react-redux";
import { RootReducer } from "../../Store/reducers";

export function ProductsStock() {
  const { t } = useTranslation();
  const productsStock = useSelector(
    (state: RootReducer) => state.productsStock,
  );

  return (
    <AppLayout>
      <PageLayout
        title={t("products_stock")}
        subtitle={
          productsStock.length
            ? `${productsStock.length} ${t("entries_found")}`
            : undefined
        }
      >
        <div className="h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4">
          <ProductsStockContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
