import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

export const PalletsColumns: (
  t: any,
  openModal?: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (t) => [
  {
    title: t("ID"),
    dataIndex: "palletID",
    key: "palletID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },

  {
    title: t("StorageZone"),
    dataIndex: "StorageZone",
    key: "StorageZone",
    render: (value) => value.name.replaceAll("_", " "),
    ellipsis: true,
  },
  {
    title: t("warehouse_name"),
    dataIndex: "StorageZone",
    render: (value) => value.WareHouse.name.replaceAll("_", " "),
    key: "warehouse",
    ellipsis: true,
  },
  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value.name} ${value.lastName}`,
    ellipsis: true,
  },
  {
    title: t("boxes"),
    dataIndex: "Box",
    key: "box",
    render: (value) => value.length,
    ellipsis: true,
  },
  {
    title: t("products"),
    dataIndex: "ProductStock",
    key: "ProductStock",
    render: (value) => value.length,
    ellipsis: true,
  },
  {
    title: t("actions"),
    dataIndex: "productID",
    key: "actions",
    width: "40px",
  },
];
