import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import Settings2FaPage from "../Pages/SettingsPages/Settings2FAPage";
import ChangePasswordPage from "../Pages/SettingsPages/ChangePasswordPage";
import ProfilePage from "../Pages/SettingsPages/ProfilePage";
import ActivityHistoryPage from "../Pages/SettingsPages/ActivityHistoryPage";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";

export function SettingsRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/settings/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/change-password"
        element={
          <PrivateRoute>
            <ChangePasswordPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/2FA"
        element={
          <PrivateRoute>
            <Settings2FaPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/activity-history"
        element={
          <PrivateRoute>
            <ActivityHistoryPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
