import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSwiftSWR } from "../Config";
import { setWarehousesDataAction } from "../Store/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Warehouse } from "../Types/PrismaSwift";

export const useWarehousesData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [warehouses, setWarehouses] = useState<Warehouse[] | []>([]);

  const { data, isLoading, error } = useSwiftSWR("/inventory/get-warehouses", {
    refreshInterval: 10000,
  });

  useEffect(() => {
    const handleWarehouses = () => {
      if (!isLoading && data) {
        const dataToShow = data.warehouses;
        setWarehouses(dataToShow);
        dispatch(setWarehousesDataAction(dataToShow));
      }
    };

    const handleWarehousesError = () => {
      if (error) {
        toast.error(
          t(
            error?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    handleWarehouses();
    handleWarehousesError();

    // eslint-disable-next-line
  }, [data, isLoading, error, dispatch]);

  return { warehouses, isLoading, error };
};
