import { MenuProps } from "antd";
import React from "react";
import { UserTypes } from "../../../Types/UserTypes";
import {
  AppstoreFilled,
  BankFilled,
  BarcodeOutlined,
  BuildFilled,
  FileProtectOutlined,
  FundOutlined,
  HddOutlined,
  PartitionOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

export type MenuItem = Required<MenuProps>["items"][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const sideBarMenu: (t: any, user: UserTypes | null) => MenuItem[] = (
  t,
  user,
) => {
  let itemsList: MenuItem[] = [
    getItem(t("swift_dashboard"), "/", <AppstoreFilled />),
  ];
  if (!user) {
    return itemsList;
  }

  /*if (!JSON.parse(user.permissions || "[]").length) {
    return itemsList;
  }*/

  itemsList = [
    ...itemsList,
    ...[
      getItem(
        t("swift_management"),
        "/swift_management/menu",
        <GroupWorkIcon />,
        [
          getItem(
            t("users_management"),
            "/swift_management/users_management",
            <FundOutlined />,
          ),
          /* getItem(
            t("volunteers_management"),
            "/swift_management/volunteers_management",
            <FundOutlined />,
          ),
          getItem(
            t("finance_management"),
            "/swift_management/finance_management",
            <FundOutlined />,
          ),
          getItem(
            t("administrative_management"),
            "/swift_management/administrative_management",
            <FundOutlined />,
          ),
          getItem(
            t("teams_management"),
            "/swift_management/teams_management",
            <FundOutlined />,
          ),
          getItem(
            t("firstAid_management"),
            "/swift_management/firstAid_management",
            <FundOutlined />,
          ),
          getItem(
            t("social_management"),
            "/swift_management/social_management",
            <FundOutlined />,
          ),*/
        ],
      ),
      getItem(t("wtcs"), "/wtc", <FileProtectOutlined />, [
        getItem(t("add_wtc"), "/wtc/add_wtc"),
        getItem(t("wtc_listing"), "/wtc/wtc_listing"),
      ]),
      getItem(t("inventory_management"), "/inventory/menu", <BuildFilled />, [
        /* getItem(t("inventory_analysis"), "/inventory", <FundOutlined />),*/
        getItem(t("warehouses"), "/inventory/warehouses", <BankFilled />),
        getItem(
          t("storage_zones"),
          "/inventory/storage-zones",
          <HddOutlined />,
        ),
        getItem(t("products"), "/inventory/products", <BarcodeOutlined />),
        getItem(t("stock"), "/inventory/products-stock/", <ProfileOutlined />, [
          getItem(t("products_stock"), "/inventory/products-stock"),
          getItem(t("add_product_stock"), "/inventory/add_product-stock"),
          getItem(t("move_product_stock"), "/inventory/move_product_stock"),
          getItem(t("boxes"), "/inventory/boxes"),
          getItem(t("add_box"), "/inventory/add_box"),
          getItem(t("pallets"), "/inventory/pallets"),
          getItem(t("add_pallet"), "/inventory/add_pallet"),
        ]),

        getItem(
          t("products-categories"),
          "/inventory/products-categories",
          <PartitionOutlined />,
        ),
      ]),
    ],
  ];
  return itemsList;
};

/*

 getItem(
        t("trc_management"),
        "/trc_management/menu",
        <ApartmentOutlinedIcon />,
        [
          getItem(
            t("committees_management"),
            "/trc_management/committees",
            <FundOutlined />,
          ),
          getItem(
            t("dues_management"),
            "/trc_management/dues_management",
            <FundOutlined />,
          ),
          getItem(
            t("fiscal_management"),
            "/trc_management/fiscal_management",
            <FundOutlined />,
          ),
          getItem(
            t("budget_management"),
            "/trc_management/budget_management",
            <FundOutlined />,
          ),
          getItem(
            t("process_management"),
            "/trc_management/process_management",
            <FundOutlined />,
          ),
        ],
      ),
      getItem(t("dns_management"), "/dns_management/menu", <DnsIcon />, [
        getItem(
          t("firstAid_PV_management"),
          "/dns_management/firstAid_PV_management",
          <FundOutlined />,
        ),
        getItem(
          t("certification_management"),
          "/dns_management/certification_management",
          <FundOutlined />,
        ),
        getItem(
          t("trainers_management"),
          "/dns_management/trainers_management",
          <FundOutlined />,
        ),
        getItem(
          t("TOT_trainers_management"),
          "/dns_management/tot_trainers",
          <FundOutlined />,
        ),
      ]),
      getItem(
        t("cpanel_management"),
        "/cpanel_management/menu",
        <ChromeReaderModeIcon />,
        [
          getItem(
            t("add_email_adresse"),
            "/cpanel_management/add_email_adresse",
            <FundOutlined />,
          ),
          getItem(
            t("emails_management"),
            "/cpanel_management/emails_management",
            <FundOutlined />,
          ),
          getItem(
            t("adresse_book"),
            "/cpanel_management/adresse_book",
            <FundOutlined />,
          ),
        ],
      ),

      /////////

      
  getItem("Submenu", "sub3", null, [
    getItem("Option 11", "11"),
    getItem("Option 12", "12"),
  ]),
]),
    getItem("Navigation One", "sub13", <MailOutlined />, [
      getItem("Option 5", "53"),
      getItem("Option 6", "63"),
      getItem("Option 7", "73"),
      getItem("Option 8", "83"),
    ]),

    getItem("Navigation Two", "sub23", <AppstoreOutlined />, [
      getItem("Option 9", "93"),
      getItem("Option 10", "103"),

      getItem("Submenu", "sub33", null, [
        getItem("Option 11", "113"),
        getItem("Option 12", "123"),
      ]),
    ]),*/
