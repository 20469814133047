import { HTMLAttributes } from "react";

interface DisabledInputProps {
  title?: string;
  content?: string;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  contentProps?: HTMLAttributes<HTMLHeadingElement>;
}

export default function DisabledInput({
  title = "",
  content = "",
  containerProps,
  contentProps,
}: DisabledInputProps) {
  return (
    <div className=" flex flex-col " {...containerProps}>
      <h4 className="text-xs font-normal text-[#383E41] mb-2">{title}</h4>
      <h5
        className="w-full border border-[#E6E8EC] text-[14px] max-h-[48px] rounded p-4 flex items-center"
        {...contentProps}
      >
        {content}
      </h5>
    </div>
  );
}
