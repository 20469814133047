import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import moment from "moment";

export const BoxesColumns: (
  t: any,
  openModal?: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (t) => [
  {
    title: t("ID"),
    dataIndex: "boxID",
    key: "boxID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },

  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value.name} ${value.lastName}`,
    ellipsis: true,
  },
  {
    title: t("created_at"),
    dataIndex: "CreatedAt",
    key: "CreatedAt",
    render: (value) => moment(value).format("DD/MM/YYYY"),
    ellipsis: true,
  },
  {
    title: t("products"),
    dataIndex: "ProductStock",
    key: "ProductStock",
    render: (value) => value.length,
    ellipsis: true,
  },
  {
    title: t("actions"),
    dataIndex: "productID",
    key: "actions",
    width: "40px",
  },
];
