import Cookies from "js-cookie";

const LANGUAGE_COOKIE_KEY = "selectedLanguage";

export const saveSelectedLanguage = (language: string) => {
  Cookies.set(LANGUAGE_COOKIE_KEY, language, { expires: 365 });
};

export const getSelectedLanguage = () => {
  return Cookies.get(LANGUAGE_COOKIE_KEY);
};
