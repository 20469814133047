import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import Barcode from "react-barcode";

export const BoxesSubColumns: (
  t: any,
  openModal?: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (t) => [
  {
    title: t("ID"),
    dataIndex: "productStockID",
    key: "productStockID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },
  {
    title: t("product_name"),
    dataIndex: "Product",
    key: "Product",
    render: (value) => {
      console.log(value);
      return value.name.replaceAll("_", " ");
    },
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },

  {
    title: t("note"),
    dataIndex: "note",
    key: "note",
    render: (value) => {
      return value ? value : "-";
    },
  },
  {
    title: t("product_unit"),
    dataIndex: "Product",
    key: "unit",
    width: 100,
    render: (value) => value.unit,
    sorter: (a, b) => a.unit.localeCompare(b.unit),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) =>
      record.unit
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("product_bareCode"),
    dataIndex: "Product",
    key: "bareCode",
    width: 200,

    onFilter: (value: any, record) => {
      if (record.bareCode) {
        return record.bareCode
          .toString()
          .includes((value as string).toLowerCase());
      } else {
        return false;
      }
    },
    render: (value) =>
      value ? (
        <div className="w-full flex justify-center items-center">
          <Barcode
            value={value.bareCode}
            displayValue={true}
            height={20}
            fontSize={10}
            margin={0}
          />
        </div>
      ) : null,
    filterSearch: true,
  },

  {
    title: t("quantity"),
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    sortDirections: ["descend", "ascend"],
    width: 100,
  },
];
