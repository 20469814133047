import { TableColumnsType, Tag } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import moment from "moment";

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  Warehouse: {
    name: string;
    location: string;
  };
}

export const ProductsStockSubColumns: (
  t: any,
) => TableColumnsType<ExpandedDataType> = (t) => [
  {
    title: t("ID"),
    dataIndex: "productStockID",
    key: "productStockID",
    width: "50px",
    render: (value) => {
      return (
        <div className="w-full flex justify-center items-center">
          <QRCodeSVG value={value} size={40} />
        </div>
      );
    },
  },
  {
    title: t("warehouse_name"),
    dataIndex: "Warehouse",
    key: "name",
    render: (value) => value.name.replaceAll("_", " "),
    ellipsis: true,
    width: 250,
    sorter: (a, b) => a.Warehouse.name.localeCompare(b.Warehouse.name),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.Warehouse.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("storage_Zone_name"),
    dataIndex: "StorageZone",
    key: "StorageZoneName",
    render: (value) => (value ? value.name.replaceAll("_", " ") : "N/A"),
    ellipsis: true,
    width: 250,
  },
  {
    title: t("quantity"),
    dataIndex: "quantity",
    key: "quantity",
    ellipsis: true,
    width: 100,
  },
  {
    title: t("note"),
    dataIndex: "note",
    key: "note",
    render: (value) => {
      return value || "-";
    },

    width: 150,
  },
  {
    title: t("manufacturing_date"),
    dataIndex: "manufacturingDate",
    key: "manufacturingDate",
    render: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "N/A";
    },
    ellipsis: true,
    width: 150,
  },

  {
    title: t("expiry_date"),
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "N/A";
    },
    ellipsis: true,
    width: 150,
  },
  {
    title: t("is_in_pallet"),
    dataIndex: "palletID",
    render: (value) => (
      <Tag color={value ? "green" : "red"} className="uppercase">
        {t(value ? "yes" : "no")}
      </Tag>
    ),
    width: 100,
  },
  {
    title: t("is_in_box"),
    dataIndex: "boxID",
    render: (value) => (
      <Tag color={value ? "green" : "red"} className="uppercase">
        {t(value ? "yes" : "no")}
      </Tag>
    ),
    width: 100,
  },
];
