import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { t } from "i18next";
import { ColumnGroupType } from "antd/es/table";
import { ProductCategory } from "../../../../Types/PrismaSwift";
import { ColumnType } from "antd/lib/table";

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

export const ProductsCategoriesColumns: (
  | ColumnGroupType<ProductCategory>
  | ColumnType<ProductCategory>
)[] = [
  {
    title: t("ID"),
    dataIndex: "productCategoryID",
    key: "productCategoryID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },
  {
    title: t("product_name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value.replaceAll("_", " "),
    defaultSortOrder: "descend",
  },

  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value.name} ${value.lastName}`,
  },
  {
    title: t("products_count"),
    dataIndex: "_count",
    key: "_count",
    // render: (value) => value.Product,
  },
  {
    title: t("actions"),
    dataIndex: "productCategoryID",
    key: "productCategoryID",
    render: (value) => `${value.type} : ${value.nameLatin}`,
  },
];
