import React, { useState } from "react";
import { Formik, FormikValues } from "formik";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";

export function AddWarehouseModalContent() {
  const initialValues = { name: "", location: "" };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const AddWarehouseHandler = async (values: FormikValues) => {
    try {
      setIsLoading(true);
      await Axios.post("/inventory/add-warehouse", values).then(
        (res) => res.data,
      );

      toast.success(t("warehouse_added"));
      setIsLoading(false);
    } catch (error: Error | any) {
      console.log(error);
      setIsLoading(false);
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  return (
    <Formik initialValues={initialValues} onSubmit={AddWarehouseHandler}>
      {({ handleChange, isValid, submitForm, handleBlur, values, errors }) => {
        return (
          <div className="p-10">
            <Input
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder={t("warehouse_name")}
              size="large"
              status={errors.name ? "error" : ""}
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
              {errors.name && t(errors.name)}
            </h3>

            <Input
              name="location"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location}
              placeholder={t("warehouse_location")}
              size="large"
              status={errors.location ? "error" : ""}
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
              {errors.location && t(errors.location)}
            </h3>

            <Button
              loading={isLoading}
              type="primary"
              className="w-full   !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
              onClick={submitForm}
              disabled={!isValid}
            >
              {t("add")}
            </Button>
          </div>
        );
      }}
    </Formik>
  );
}
