import { ProductStock } from "../../Types/PrismaSwift";
import { SET_PRODUCTS_STOCK_DATA, USER_LOGOUT } from "../actionTypes";

export type ProductsStockState = ProductStock[] | [];

const initialState: ProductStock[] | [] = [];
const productsStockReducer = (
  state: ProductsStockState = initialState,
  { type, payload }: { type: string; payload: ProductStock[] | [] },
): ProductsStockState => {
  switch (type) {
    case USER_LOGOUT:
      return initialState;
    case SET_PRODUCTS_STOCK_DATA:
      return payload;

    default:
      return state;
  }
};
export default productsStockReducer;
