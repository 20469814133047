import React from "react";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import SwiftUsersContent from "../../Components/Features/SwiftManagment/SwiftUsers/SwiftUsersContent";

function UsersManagementPage() {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("users_management")}>
        <div className="min-h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4 ">
          <SwiftUsersContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}

export default UsersManagementPage;
