import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { ColumnGroupType } from "antd/es/table";
import { Warehouse } from "../../../../Types/PrismaSwift";
import { ColumnType } from "antd/lib/table";

export const WarehousesColumns: (
  t: any,
  openModal: any,
) => (ColumnGroupType<Warehouse> | ColumnType<Warehouse>)[] = (t) => [
  {
    title: t("ID"),
    dataIndex: "warehouseID",
    key: "warehouseID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },
  {
    title: t("warehouse_name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value.replaceAll("_", " "),
    ellipsis: true,
    width: 300,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("warehouse_location"),
    dataIndex: "location",
    key: "location",
    ellipsis: true,
    width: 400,
    sorter: (a, b) => {
      return a.location.localeCompare(b.location);
    },
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) => record.location.startsWith(value),
    filterSearch: true,
  },
  {
    title: t("created_by"),
    dataIndex: "CreatedBy",
    key: "CreatedBy",
    render: (value) => `${value.name} ${value.lastName}`,
    ellipsis: true,
  },
  {
    title: t("storage_zone_count"),
    dataIndex: "StorageZone",
    key: "StorageZone",
    render: (value) => value?.length,
    sorter: (a, b) => a.StorageZone.length - b.StorageZone.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: t("committee"),
    dataIndex: "Committee",
    key: "Committee",
    render: (value) => `${value.type} : ${value.nameLatin}`,
    ellipsis: true,
    sorter: (a, b) =>
      a.Committee.nameLatin.localeCompare(b.Committee.nameLatin),
    sortDirections: ["descend", "ascend"],
  },
];

/*{
  title: t("actions"),
      dataIndex: "warehouseID",
    key: "warehouseID",
    render: () => (
    <div className="w-full flex justify-center items-center">
      <DeleteForeverIcon
          fontSize="small"
          color="error"
          className="cursor-pointer"
      />
      <LocalPrintshopIcon
          fontSize="small"
          color="action"
          className="cursor-pointer"
          onClick={() => openModal(true)}
      />
    </div>
),
},*/
