import React from "react";
import "./Assets/styles/index.scss";
import {
  AppRoutes,
  AuthRoutes,
  GeneralRoutes,
  InventoryRoutes,
  SwiftManagementRoutes,
} from "./Routes";
import { ToastContainer } from "react-toastify";
import { SettingsRoutes } from "./Routes/SettingsRoutes";
import "moment/locale/fr";
import "moment/locale/ar";
import { WtcRoutes } from "./Routes/WtcRoutes";

function App(): JSX.Element {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <AuthRoutes />
      <GeneralRoutes />
      <AppRoutes />
      <InventoryRoutes />
      <SwiftManagementRoutes />
      <SettingsRoutes />
      <WtcRoutes />
    </>
  );
}

export default App;
