import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authRecucer";
import warehousesReducer, { WarehouseState } from "./warehousesReducer";
import productsReducer, { ProductsState } from "./ProductsReducer";
import productsStockReducer, {
  ProductsStockState,
} from "./ProductsStockReducer";
import productsCategoriesReducer, {
  ProductsCategoriesState,
} from "./ProductsCategoriesReducer";
import SwiftUsersReducer, { SwiftUsersState } from "./SwiftUsersReducer";

const rootReducer = combineReducers({
  authentication: authReducer,
  users: SwiftUsersReducer,
  volunteers: authReducer,
  committees: authReducer,
  regionsList: authReducer,
  files: authReducer,
  courses: authReducer,
  domains: authReducer,
  adresseBook: authReducer,
  donations: authReducer,
  inventoryData: authReducer,

  warehouses: warehousesReducer,
  storageZones: authReducer,
  products: productsReducer,
  productsStock: productsStockReducer,
  productsCategories: productsCategoriesReducer,
});

export interface RootReducer {
  authentication: AuthState;
  users: SwiftUsersState;
  volunteers: AuthState;
  committees: AuthState;
  regionsList: AuthState;
  files: AuthState;
  courses: AuthState;
  domains: AuthState;
  adresseBook: AuthState;
  donations: AuthState;
  inventoryData: AuthState;

  warehouses: WarehouseState;
  storageZones: AuthState;
  products: ProductsState;
  productsStock: ProductsStockState;
  productsCategories: ProductsCategoriesState;
}
export default rootReducer;
