import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import { DashboardPage } from "../Pages";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";

export function AppRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
