import { TableColumnsType, Tag } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import moment from "moment";
import Barcode from "react-barcode";

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  Warehouse: {
    name: string;
    location: string;
  };
}

export const WarehouseProductsStockColumns: (
  t: any,
) => TableColumnsType<ExpandedDataType> = (t) => [
  {
    title: t("ID"),
    dataIndex: "productStockID",
    key: "productStockID",
    width: "50px",
    render: (value) => {
      console.log(value);
      return (
        <div className="w-full flex justify-center items-center">
          <QRCodeSVG value={value} size={40} />
        </div>
      );
    },
  },
  {
    title: t("product_name"),
    dataIndex: "Product",
    render: (value) => value.name,
  },
  {
    title: t("product_bareCode"),
    dataIndex: "Product",
    render: (value) =>
      value.bareCode ? (
        <div className="w-full flex justify-center items-center">
          <Barcode
            value={value.bareCode}
            displayValue={true}
            height={20}
            fontSize={10}
            margin={0}
          />
        </div>
      ) : null,
    width: 170,
  },

  {
    title: t("manufacturing_date"),
    dataIndex: "manufacturingDate",
    key: "manufacturingDate",
    render: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "N/A";
    },
    ellipsis: true,
    width: 150,
  },
  {
    title: t("expiry_date"),
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "N/A";
    },
    ellipsis: true,
    width: 150,
  },
  {
    title: t("quantity"),
    dataIndex: "quantity",
    key: "quantity",
    width: 70,
  },

  {
    title: t("is_in_pallet"),
    dataIndex: "palletID",
    render: (value) => (
      <Tag color={value ? "green" : "red"} className="uppercase">
        {t(value ? "yes" : "no")}
      </Tag>
    ),
    width: 100,
  },
  {
    title: t("is_in_box"),
    dataIndex: "boxID",
    render: (value) => (
      <Tag color={value ? "green" : "red"} className="uppercase">
        {t(value ? "yes" : "no")}
      </Tag>
    ),
    width: 100,
  },
];
