import React from "react";
import { Route } from "react-router-dom";
import { AboutUs, ContactUs, PrivacyPolicy, TermsOfService } from "../Pages";
import { RenderRoutes } from "../Config";
export function GeneralRoutes() {
  return (
    <RenderRoutes>
      <Route path="/contact_us" element={<ContactUs />} />
      <Route path="/about_us" element={<AboutUs />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/terms_of_service" element={<TermsOfService />} />
    </RenderRoutes>
  );
}

