import { useSelector } from "react-redux";
import { RootReducer } from "../../../../Store/reducers";
import React, { useMemo, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Space,
  Spin,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useProductData, useWarehousesData } from "../../../../Hooks";
import { useTranslation } from "react-i18next";
import Meta from "antd/lib/card/Meta";
import { QRCodeSVG } from "qrcode.react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import moment from "moment";
import LogoTrcSimple from "../../../../Assets/SVGs/LogoTrcSimple";
import { ProductStock } from "../../../../Types/PrismaSwift";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";

const { RangePicker } = DatePicker;

export function AddProductStock() {
  const { t } = useTranslation();
  useWarehousesData();
  useProductData();
  const products = useSelector((state: RootReducer) => state.products);
  const warehouses = useSelector((state: RootReducer) => state.warehouses);

  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(
    null,
  );
  const [selectedStorageZone, setSelectedStorageZone] = useState<
    string | undefined
  >(undefined);
  const [productsToAdd, setProductsToAdd] = useState<any>([]);

  const [searchInput, setSearchInput] = useState<string>("");
  const [canProductExpier, setCanProductExpier] = useState(false);
  const [addToBox, setAddToBox] = useState(false);
  const setWarehouseHandler = (e: any) => {
    setSelectedWarehouse(e.key);
  };

  const setStorageZoneHandler = (e: any) => {
    setSelectedStorageZone(e.key);
  };

  const productAsOptions = useMemo(
    () =>
      products.length
        ? products.map((product) => ({
            value: product.productID,
            label: product.name,
          }))
        : [],
    [products],
  );

  const [options, setOptions] =
    useState<{ value: string; label: string }[]>(productAsOptions);

  const handleSelectedProduct = (e: any) => {
    const foundProduct = products.filter(
      (product) => product.productID === e || product.bareCode === e,
    );

    if (!foundProduct.length) {
      return alert("Product not found");
    }

    setSearchInput("");

    if (
      productsToAdd.some((product: any) => {
        return product.productID === foundProduct[0].productID;
      })
    ) {
      setProductsToAdd((prev: any) => {
        return prev.map((product: any) => {
          if (product.productID === foundProduct[0].productID) {
            return { ...product, quantity: product.quantity + 1 };
          } else {
            return product;
          }
        });
      });
    } else {
      const productTemplate = {
        quantity: 1,
        note: "",
        productID: foundProduct[0].productID,
        warehouseID: selectedWarehouse,
        storageZoneID: selectedStorageZone,
      };
      setProductsToAdd((prev: any) => [...prev, productTemplate]);
    }
  };

  const dateFormat = "DD/MM/YYYY";
  const handleSearch = (value: string) => {
    if (!value.length) {
      return setOptions(productAsOptions);
    }
    if (value.length >= 3) {
      let res: { value: string; label: string }[] = [];

      products.forEach((product) => {
        if (
          product.name.toLowerCase().startsWith(value.toLowerCase()) ||
          product.bareCode?.startsWith(value)
        ) {
          res.push({
            value: product.productID,
            label: product.name,
          });
        }
      });

      setOptions(res);
    }
  };

  const changeQteValue = (productID: string, qte: any) => {
    setProductsToAdd((prev: any) => {
      return prev.map((product: any) => {
        if (product.productID === productID) {
          return { ...product, quantity: qte ? qte : 1 };
        } else {
          return product;
        }
      });
    });
  };

  const deleteProductToAdd = (productID: string) => {
    setProductsToAdd((prev: any) => {
      return prev.filter((product: any) => {
        return product.productID !== productID;
      });
    });
  };

  const handleInputChange = (value: string) => {
    if (!value.length) {
      setSearchInput("");
      return setOptions(productAsOptions);
    } else {
      setSearchInput(value);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPrintBoxOpen, setIsPrintBoxOpen] = useState<boolean>(false);
  const [box, setBox] = useState<any>(null);
  const addStockHandler = async () => {
    try {
      setIsLoading(true);
      let productsToAddBody = productsToAdd.map((productsToAdd: any) => {
        return {
          ...productsToAdd,
          manufacturingDate: productsToAdd.manufacturingDate
            ? new Date(productsToAdd.manufacturingDate)
            : undefined,
          expiryDate: productsToAdd.expiryDate
            ? new Date(productsToAdd.expiryDate)
            : undefined,
        };
      });

      if (addToBox) {
        const data: any = {};
        if (selectedStorageZone) {
          data.storageZoneId = selectedStorageZone;
        }

        const createdBox = await Axios.post("/inventory/add-box", data).then(
          (res) => {
            productsToAddBody = productsToAdd.map((productsToAdd: any) => {
              return {
                ...productsToAdd,
                boxID: res.data["box"] ? res.data["box"].boxID : undefined,
              };
            });

            return res.data["box"];
          },
        );

        setBox(createdBox);
      }

      const addedStock = await Axios.post(
        "/inventory/add-products-stock",
        productsToAddBody,
      ).then((res) => res.data["add-products-stock"]);

      if (addToBox) {
        console.log(addedStock[0].boxID);
        const addedBox = await Axios.get(
          `/inventory/get-box/${addedStock[0].boxID}`,
        ).then((res) => {
          return res?.data?.box;
        });
        setIsPrintBoxOpen(true);
        setBox(addedBox);
      }

      toast.success(t("product_stock_added"));
      setProductsToAdd([]);
      setIsLoading(false);
    } catch (error: Error | any) {
      console.log(error);
      setIsLoading(false);
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const changeProductValidationHandler = (values: any, productID: string) => {
    const changeProductsToAdd = productsToAdd.map((product: any) => {
      if (product.productID === productID) {
        if (canProductExpier) {
          return {
            ...product,
            manufacturingDate: values
              ? dayjs(values[0], dateFormat)
              : undefined,
            expiryDate: values ? dayjs(values[1], dateFormat) : undefined,
          };
        } else {
          return {
            ...product,
            manufacturingDate: values ? dayjs(values, dateFormat) : undefined,
          };
        }
      } else {
        return product;
      }
    });

    setProductsToAdd(changeProductsToAdd);
  };

  const changeProductNoteHandler = (note: any, productID: string) => {
    const changeProductsNoteToAdd = productsToAdd.map((product: any) => {
      if (product.productID === productID) {
        return {
          ...product,
          note: note.target.value,
        };
      } else {
        return product;
      }
    });

    setProductsToAdd(changeProductsNoteToAdd);
  };

  const BoxTicketRef = useRef(null);

  const printBoxTicketHandler = useReactToPrint({
    content: () => BoxTicketRef.current,
  });

  if (!warehouses.length) {
    return (
      <div className="max-w-full h-96 flex flex-col   justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col min-h-full h-1/2 p-5">
      {isPrintBoxOpen && (
        <Modal
          title={t("recap_print_box")}
          open={isPrintBoxOpen}
          onOk={printBoxTicketHandler}
          onCancel={() => setIsPrintBoxOpen(false)}
        >
          <div className=" my-5">
            <List
              bordered
              size="default"
              rootClassName="mb-4 "
              itemLayout="horizontal"
            >
              {box &&
                box.ProductStock.map((stock: any) => {
                  return (
                    <List.Item className="border-black">
                      <List.Item.Meta
                        title={
                          <div className="w-full flex justify-between">
                            <span>{stock.Product.name}</span>
                            <span>
                              {`${t("quantity")} : ${stock.quantity}`}
                            </span>
                          </div>
                        }
                        description={
                          <div className=" w-full flex justify-between ">
                            <span> {`${t("note")} : ${stock.note}`} </span>
                            <span>
                              {`${t("expiry_date")} : ${
                                (stock.expiryDate &&
                                  moment(stock.expiryDate).format("MM/YYYY")) ||
                                "N/A"
                              }`}
                            </span>
                          </div>
                        }
                      />
                    </List.Item>
                  );
                })}
            </List>
          </div>
        </Modal>
      )}

      <div className="hidden">
        <div className="hidden">
          {box && (
            <div ref={BoxTicketRef} className="flex w-full p-5 flex-col mb-5">
              <div className="flex w-full flex-nowrap gap-5 ">
                <div className="flex  flex-col w-full   justify-center px-5">
                  <LogoTrcSimple />

                  <h2 className="w-full text-center text-2xl font-bold mt-3 uppercase">
                    BOX
                  </h2>
                </div>

                <QRCodeSVG value={box?.boxID} height={90} />
              </div>

              {box?.ProductStock ? (
                <div className="w-full flex-col flex mt-5 text-xs ">
                  <List
                    bordered
                    size="small"
                    rootClassName="mb-4  "
                    itemLayout="horizontal"
                    header={
                      <h2 className="font-bold uppercase !border-b-2 !border-black ">
                        Products in Box
                      </h2>
                    }
                  >
                    {box?.ProductStock?.map((ps: ProductStock) => {
                      return (
                        <List.Item
                          key={ps.productStockID}
                          className=" !border-b-2 !border-black "
                        >
                          <List.Item.Meta
                            title={
                              <div className="w-full flex justify-between font-bold">
                                <span>{ps.Product.name}</span>
                                <span>{`Quantity : ${ps.quantity}`}</span>
                              </div>
                            }
                            description={
                              <div className=" w-full flex flex-col justify-center text-black  ">
                                <div className=" w-full flex justify-between ">
                                  <span>
                                    {" "}
                                    <Barcode
                                      value={ps.Product.bareCode || ""}
                                      displayValue={true}
                                      width={1}
                                      height={20}
                                      fontSize={10}
                                      margin={10}
                                    />
                                  </span>
                                  <span className="flex justify-center items-center font-bold">
                                    {`EXP : ${
                                      (ps.expiryDate &&
                                        moment(ps.expiryDate).format(
                                          "MM/YYYY",
                                        )) ||
                                      "N/A"
                                    }`}
                                  </span>
                                </div>
                                {ps.note && (
                                  <div className=" w-full flex justify-center ">
                                    {`Note : ${ps.note}`}
                                  </div>
                                )}
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    })}
                  </List>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div>
        <h3 className="mb-5 text-xl font-medium ">
          {t("select_where_to_add_your_stock")}
        </h3>
        <div className="flex gap-5">
          <Dropdown
            menu={{
              onClick: setWarehouseHandler,
              items: warehouses.map((warehouse) => {
                return {
                  key: warehouse.warehouseID,
                  label: warehouse.name.replaceAll("_", " "),
                };
              }),
            }}
          >
            <Button>
              <Space>
                {selectedWarehouse
                  ? warehouses
                      ?.filter(
                        (warehouse) =>
                          warehouse.warehouseID === selectedWarehouse,
                      )?.[0]
                      ?.name?.replaceAll("_", " ")
                  : t("select_warehouse")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          {selectedWarehouse &&
          warehouses?.filter(
            (warehouse) => warehouse.warehouseID === selectedWarehouse,
          )?.[0].StorageZone.length ? (
            <Dropdown
              disabled={!selectedWarehouse}
              menu={{
                onClick: setStorageZoneHandler,
                items:
                  (selectedWarehouse &&
                    warehouses
                      ?.filter(
                        (warehouse) =>
                          warehouse.warehouseID === selectedWarehouse,
                      )?.[0]
                      .StorageZone.map((StorageZone) => {
                        return {
                          key: StorageZone.storageZoneID,
                          label: StorageZone.name.replaceAll("_", " "),
                        };
                      })) ||
                  [],
              }}
            >
              <Button>
                <Space>
                  {selectedStorageZone
                    ? warehouses
                        ?.filter(
                          (warehouse) =>
                            warehouse.warehouseID === selectedWarehouse,
                        )?.[0]
                        .StorageZone.filter(
                          (storageZone) =>
                            storageZone.storageZoneID === selectedStorageZone,
                        )[0]
                        .name.replaceAll("_", " ")
                    : t("select_storage_zone")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          ) : null}
        </div>
        <Divider />
        <h3 className="mb-5 text-xl font-medium ">
          {t("scan_or_search_product")}
        </h3>
        {products.length ? (
          <AutoComplete
            style={{ width: 500 }}
            onSearch={handleSearch}
            options={options}
            onSelect={handleSelectedProduct}
            onChange={handleInputChange}
            value={searchInput}
          >
            <Input.Search
              size="large"
              placeholder={t("scan_or_search_product")}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  handleSelectedProduct(e.target.value);
                }
              }}
            />
          </AutoComplete>
        ) : (
          <Spin />
        )}
        <Divider />
        <h3 className="mb-10 text-xl font-medium ">
          {t("product_to_add_list")}
        </h3>
        {productsToAdd.length ? (
          productsToAdd?.map((prod: any) => {
            return (
              <div
                className="w-2/3 mx-auto flex flex-col gap-3"
                key={prod.productID}
              >
                <div className="mb-3">
                  <List bordered size="default">
                    <Card
                      actions={[
                        <DeleteOutlined
                          key="delete"
                          onClick={() => deleteProductToAdd(prod.productID)}
                        />,
                        <EllipsisOutlined key="ellipsis" />,
                      ]}
                    >
                      <Meta
                        avatar={
                          <QRCodeSVG
                            value={prod.productID}
                            height={60}
                            width={60}
                          />
                        }
                        title={
                          products.filter(
                            (product) => product.productID === prod.productID,
                          )[0].name
                        }
                        description={
                          <div className="w-full flex flex-col">
                            <div className="  flex justify-between">
                              {`${t("quantity")} ${prod.quantity}`}
                              <InputNumber
                                min={1}
                                value={prod.quantity}
                                onChange={(value) =>
                                  changeQteValue(prod.productID, value)
                                }
                              />
                            </div>
                            <Divider />
                            <h3 className="text-sm text-gray-400 mb-3">
                              {t("set_validity")}
                            </h3>
                            <div className=" text-gray-700 mb-3 flex justify-between">
                              {t("can_product_expier")} :
                              <Switch
                                value={canProductExpier}
                                onChange={() => {
                                  setCanProductExpier(
                                    (prevState) => !prevState,
                                  );
                                }}
                              />
                            </div>
                            {!canProductExpier ? (
                              <DatePicker
                                value={
                                  prod.manufacturingDate &&
                                  dayjs(prod.manufacturingDate, dateFormat)
                                }
                                onChange={(date) => {
                                  changeProductValidationHandler(
                                    date,
                                    prod.productID,
                                  );
                                }}
                              />
                            ) : (
                              <RangePicker
                                value={[
                                  prod.manufacturingDate
                                    ? dayjs(prod.manufacturingDate, dateFormat)
                                    : null,
                                  prod.expiryDate
                                    ? dayjs(prod.expiryDate, dateFormat)
                                    : null,
                                ]}
                                onChange={(date) => {
                                  changeProductValidationHandler(
                                    date,
                                    prod.productID,
                                  );
                                }}
                              />
                            )}

                            <Divider />

                            <TextArea
                              value={prod.note}
                              placeholder={t("note")}
                              allowClear
                              onChange={(e) =>
                                changeProductNoteHandler(e, prod.productID)
                              }
                            />
                          </div>
                        }
                      />
                    </Card>
                  </List>
                </div>
              </div>
            );
          })
        ) : (
          <h3>{t("no_product_selected")}</h3>
        )}
        <Divider />

        <label about={t("add_product_stock_to_box")}>
          <span className="mr-5">{t("add_product_stock_to_box")}</span>
          <Radio.Group
            onChange={(e) => {
              setAddToBox(e.target.value);
            }}
            value={addToBox}
          >
            <Radio value={true}>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Radio.Group>
        </label>
      </div>
      <div className="w-full flex p-4 my-4 justify-center">
        <Button
          type="primary"
          className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757] w-full md:w-1/2 !text-gray-100"
          disabled={!selectedWarehouse || !productsToAdd.length}
          loading={isLoading}
          onClick={addStockHandler}
        >
          {t("add_product_stock")}
        </Button>
      </div>
    </div>
  );
}
