import { Warehouse } from "../../Types/PrismaSwift";
import { SET_WAREHOUSES_DATA, USER_LOGOUT } from "../actionTypes";

export type WarehouseState = Warehouse[] | [];

const initialState: Warehouse[] | [] = [];
const warehousesReducer = (
  state: WarehouseState = initialState,
  { type, payload }: { type: string; payload: Warehouse[] | [] },
): WarehouseState => {
  switch (type) {
    case USER_LOGOUT:
      return initialState;
    case SET_WAREHOUSES_DATA:
      return payload;

    default:
      return state;
  }
};
export default warehousesReducer;
