import React, { useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import { Axios, useSwiftSWR } from "../../../../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Card, Dropdown, Input, Space, Spin } from "antd";
import { DownOutlined, WarningOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd/es/menu";
import { ProductCategory } from "../../../../Types/PrismaSwift";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../../Store/reducers";

export function AddProductModalContent() {
  const initialValues = {
    name: "",
    description: "",
    unit: "PIECE",
    productCategoryId: "",
    bareCode: "",
  };

  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const AddProductHandler = async (values: FormikValues) => {
    try {
      setIsAddLoading(true);
      await Axios.post("/inventory/add-product", values).then(
        (res) => res.data,
      );

      toast.success(t("product_added"));
      setIsAddLoading(false);
    } catch (error: Error | any) {
      setIsAddLoading(false);
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const [productsCategories, setProductsCategories] = useState<any>([]);

  const { data, isLoading, error } = useSwiftSWR(
    "/inventory/get-product-categories",
    {
      refreshInterval: 100000,
    },
  );
  useEffect(() => {
    if (!isLoading && data) {
      const dataToShow = data["product-categories"];

      setProductsCategories(
        dataToShow.map((category: ProductCategory) => {
          return {
            label: category?.name?.replaceAll("_", " "),
            key: category.productCategoryID,
          };
        }),
      );
    }
    if (error) {
      toast.error(
        t(
          error?.response.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
    // eslint-disable-next-line
  }, [data, isLoading, error]);

  const unitItems: MenuProps["items"] = [
    { label: "PIECE", key: "PIECE" },
    { label: "GRAM", key: "GRAM" },
    { label: "KILOGRAM", key: "KILOGRAM" },
    { label: "MILLILITER", key: "MILLILITER" },
    { label: "LITER", key: "LITER" },
  ];

  const products = useSelector((state: RootReducer) => state.products);

  return (
    <Formik initialValues={initialValues} onSubmit={AddProductHandler}>
      {({
        handleChange,
        isValid,
        submitForm,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => {
        return !isLoading ? (
          <div className="p-10">
            <Input
              name="bareCode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bareCode}
              placeholder={t("product_bareCode")}
              size="large"
              status={errors.bareCode ? "error" : ""}
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
              {errors.bareCode && t(errors.bareCode)}
            </h3>

            {products.some(
              (product) => product.bareCode === values.bareCode,
            ) ? (
              <div className="flex flex-col w-full">
                <span className="mb-5 text-red-800 font-bold text-xl w-full flex justify-center">
                  <WarningOutlined className="mr-5" /> {t("product_exists")}
                </span>
                <Card
                  title={
                    products.filter(
                      (product) => product.bareCode === values.bareCode,
                    )[0].name
                  }
                >
                  <p>
                    {t("product_unit")}
                    {" :  "}
                    {
                      products.filter(
                        (product) => product.bareCode === values.bareCode,
                      )[0].unit
                    }
                  </p>
                  <p>
                    {t("product_category")}
                    {" :  "}
                    {
                      products.filter(
                        (product) => product.bareCode === values.bareCode,
                      )[0].ProductCategory.name
                    }
                  </p>
                  <p>
                    {" "}
                    {t("product_bareCode")}
                    {" :  "}
                    {
                      products.filter(
                        (product) => product.bareCode === values.bareCode,
                      )[0].bareCode
                    }
                  </p>
                </Card>
              </div>
            ) : (
              <>
                <Input
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder={t("product_name")}
                  size="large"
                  status={errors.name ? "error" : ""}
                />
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.name && t(errors.name)}
                </h3>

                <Input
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  placeholder={t("product_description")}
                  size="large"
                  status={errors.description ? "error" : ""}
                />
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.description && t(errors.description)}
                </h3>

                <Dropdown
                  menu={{
                    items: unitItems,
                    onClick: (e) => setFieldValue("unit", e.key),
                  }}
                  className="w-full flex !justify-between !h-10"
                >
                  <Button className="w-full  ">
                    <Space className=" w-full flex justify-between  ">
                      {values.unit}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.unit && t(errors.unit)}
                </h3>

                <Dropdown
                  menu={{
                    items: productsCategories,
                    onClick: (e) => setFieldValue("productCategoryId", e.key),
                  }}
                  className="w-full flex !justify-between !h-10"
                >
                  <Button className="w-full  ">
                    <Space className=" w-full flex justify-between  ">
                      {values.productCategoryId
                        ? productsCategories.filter(
                            (caegory: any) =>
                              caegory.key === values.productCategoryId,
                          )[0]?.label
                        : t("product_category")}

                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
                  {errors.unit && t(errors.unit)}
                </h3>

                <Button
                  loading={isAddLoading}
                  type="primary"
                  className="w-full   !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  {t("add")}
                </Button>
              </>
            )}
          </div>
        ) : (
          <Spin />
        );
      }}
    </Formik>
  );
}
