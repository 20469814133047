import { TableColumnsType } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import Barcode from "react-barcode";
import { ProductsColumnExpandedDataType } from "../Products/ProductsColumns";

export const ProductsStockColumns: (
  t: any,
) => TableColumnsType<ProductsColumnExpandedDataType> = (t) => [
  {
    title: t("ID"),
    dataIndex: "productID",
    key: "productID",
    width: "50px",
    render: (value) => (
      <div className="w-full flex justify-center items-center">
        <QRCodeSVG value={value} size={40} />
      </div>
    ),
  },
  {
    title: t("product_name"),
    dataIndex: "name",
    key: "name",
    render: (value) => value.replaceAll("_", " "),
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record: any) =>
      record.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,

    width: 400,
  },

  {
    title: t("product_unit"),
    dataIndex: "unit",
    key: "unit",
    width: 100,
    sorter: (a, b) => a.unit.localeCompare(b.unit),
    sortDirections: ["descend", "ascend"],
    onFilter: (value: any, record) =>
      record.unit
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("product_bareCode"),
    dataIndex: "bareCode",
    key: "bareCode",
    width: 200,
    onFilter: (value: any, record) => {
      if (record.bareCode) {
        return record.bareCode
          .toString()
          .includes((value as string).toLowerCase());
      } else {
        return false;
      }
    },
    render: (value) =>
      value ? (
        <div className="w-full flex justify-center items-center">
          <Barcode
            value={value}
            displayValue={true}
            height={20}
            fontSize={10}
            margin={0}
          />
        </div>
      ) : null,
    filterSearch: true,
  },
  {
    title: t("product_category"),
    dataIndex: "ProductCategory",
    key: "ProductCategory",
    render: (value) => value?.name?.replaceAll("_", " "),
    width: 150,
    onFilter: (value: any, record) =>
      record.ProductCategory.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterSearch: true,
  },
  {
    title: t("quantity"),
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    sortDirections: ["descend", "ascend"],
    width: 100,
  },
];
