import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import { StorageZonesContent } from "../../Components/Features/Inventory/StorageZones/StorageZonesContent";

export function StorageZones() {
  const { t } = useTranslation();
  /*
    const storageZones = useSelector((state: RootReducer) => state.storageZones);
      subtitle={
          storageZones.length
              ? `${storageZones.length} ${t("entries_found")}`
              : undefined
      }*/

  return (
    <AppLayout>
      <PageLayout title={t("storage_zones")}>
        <div className="h-fit max-w-1/2  bg-white rounded-xl shadow-accent-foreground shadow-2xl p-4">
          <StorageZonesContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
}
