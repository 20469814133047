import {
  CHANGE_OTP_STATUS,
  GENERATE_OTP_CODE,
  USER_LOGIN,
  USER_LOGOUT,
} from "../actionTypes";
import { UserTypes } from "../../Types/UserTypes";
import { Axios } from "../../Config";
import { toast } from "react-toastify";
import { t } from "i18next";

export const userLoginAction = (payload: UserTypes) => {
  window.localStorage.setItem("token", payload.token);
  return {
    type: USER_LOGIN as typeof USER_LOGIN,
    payload: payload,
  };
};

export const userLogoutAction = () => {
  window.localStorage.clear();
  return {
    type: USER_LOGOUT as typeof USER_LOGOUT,
    payload: null,
  };
};

export const changeOtpStatusAction = (payload: boolean) => {
  return {
    type: CHANGE_OTP_STATUS as typeof CHANGE_OTP_STATUS,
    payload: {
      isOtpEnabled: payload,
    },
  };
};

export const generateOtpAction = (payload: {
  base32: string;
  OtpURL: string;
}) => {
  return {
    type: GENERATE_OTP_CODE as typeof GENERATE_OTP_CODE,
    payload: {
      otpBase32: payload.base32,
      otpAuthUrl: payload.OtpURL,
    },
  };
};

export const verifyOtpAction = () => {
  return {
    type: GENERATE_OTP_CODE as typeof GENERATE_OTP_CODE,
    payload: {
      isOtpVerified: true,
      isOtpEnabled: true,
    },
  };
};

export const checkIsTokenValidHandler = async (
  navigate: any,
  token: string | null,
) => {
  try {
    return await Axios.get("/auth/check", {
      headers: { Authorization: token },
    }).then((response) => {
      return response.data?.user;
    });
  } catch (error: any) {
    navigate("/login");
    localStorage.removeItem("token");
    toast.error(
      t(error?.response?.data?.message["RESPONSE_CODE"]) ||
        "SERVICE_UNAVAILABLE_MESSAGE",
    );
  }
};
