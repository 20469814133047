import { Product } from "../../Types/PrismaSwift";
import { SET_PRODUCTS_DATA, USER_LOGOUT } from "../actionTypes";

export type ProductsState = Product[] | [];

const initialState: Product[] | [] = [];
const productsReducer = (
  state: ProductsState = initialState,
  { type, payload }: { type: string; payload: Product[] | [] },
): ProductsState => {
  switch (type) {
    case USER_LOGOUT:
      return initialState;
    case SET_PRODUCTS_DATA:
      return payload;

    default:
      return state;
  }
};
export default productsReducer;
