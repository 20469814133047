import { FC, PropsWithChildren, useState } from "react";
import { NavBar } from "../Components";
import { SideBar } from "../Components/Features/SideBar/SideBar";
import { useTranslation } from "react-i18next";

export const AppLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <main
      className={`w-screen overflow-x-hidden h-screen max-h-screen flex flex-nowrap  relative box-border ${
        language === "ar" ? "right-to-left" : ""
      }`}
    >
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />

      <div className="w-full max-w-full box-border h-screen min-h-screen max-h-screen  bg-[#F5F5F5] flex flex-col relative">
        <NavBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="w-full max-w-full h-fit  flex flex-col overflow-x-hidden overflow-auto relative ">
          {children}
        </div>
      </div>
    </main>
  );
};
